<template>
    <div>
        <!-- 服务范围 小程序-->
        <div class="services-banner"></div>
        <!-- 微信流量 -->
        <div class="applet-flow">
            <div class="applet-content">
                <div class="applet-text-content">
                    <div class="applet-text-title">
                        10亿微信用户流量入口及附近的人，即用即走
                    </div>
                    <div class="applet-text-desc">
                        集展示、互动、交易为一体，微信营销强大工具
                    </div>
                </div>
                <div class="applet-flow-total">
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow15.png" alt="" />
                        </div>
                        <div class="applet-flow-text">二维码</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow10.png" alt="" />
                        </div>
                        <div class="applet-flow-text">微信查找小程序</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow9.png" alt="" />
                        </div>
                        <div class="applet-flow-text">分享到好友和群</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow2.png" alt="" />
                        </div>
                        <div class="applet-flow-text">附近小程序</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow1.png" alt="" />
                        </div>
                        <div class="applet-flow-text">与已有的公众号关联</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow14.png" alt="" />
                        </div>
                        <div class="applet-flow-text">客户消息收发</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow11.png" alt="" />
                        </div>
                        <div class="applet-flow-text">小程序浏览历史</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow8.png" alt="" />
                        </div>
                        <div class="applet-flow-text">置顶小程序</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow3.png" alt="" />
                        </div>
                        <div class="applet-flow-text">公众号菜单跳转小程序</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow4.png" alt="" />
                        </div>
                        <div class="applet-flow-text">消息模块跳转小程序</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow13.png" alt="" />
                        </div>
                        <div class="applet-flow-text">APP分享跳转小程序</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow12.png" alt="" />
                        </div>
                        <div class="applet-flow-text">兼容已有的二维码</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow7.png" alt="" />
                        </div>
                        <div class="applet-flow-text">公众号图文名片跳转</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow6.png" alt="" />
                        </div>
                        <div class="applet-flow-text">朋友圈广告</div>
                    </div>
                    <div class="applet-flow-item">
                        <div class="applet-flow-img">
                            <img src="@/assets/images/flow5.png" alt="" />
                        </div>
                        <div class="applet-flow-text">持续升级中...</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="applet-ds">
            <div class="applet-ds-content">
                <div class="applet-ds-text-content">
                    <div class="applet-ds-title">
                        电商平台定制开发 提供多种商业模式解决方案
                    </div>
                    <div class="applet-ds-desc">
                        集展示、互动、交易为一体，微信营销强大工具
                    </div>
                </div>
                <div class="applet-ds-img-total">
                    <div class="applet-ds-img-item">
                        <div class="applet-ds-img">
                            <img src="@/assets/images/applet-sy1.png" alt="" />
                        </div>
                        <div class="applet-ds-text">
                            <div class="applet-ds-text-title">
                                B2B2C多商家商城
                            </div>
                            <div class="applet-ds-text-desc">
                                平台自营+供应商入住，Apache主流开放开源框架进行开发，高端的高并发解决方案，可扩展性强
                            </div>
                        </div>
                    </div>

                    <div class="applet-ds-img-item">
                        <div class="applet-ds-img">
                            <img src="@/assets/images/applet-sy2.png" alt="" />
                        </div>
                        <div class="applet-ds-text">
                            <div class="applet-ds-text-title">
                                B2B商家对商家
                            </div>
                            <div class="applet-ds-text-desc">
                                支持线上线下交易，批量采购，行业资讯，竞价直通车，供求、求购方数据准确整合
                            </div>
                        </div>
                    </div>

                    <div class="applet-ds-img-item">
                        <div class="applet-ds-img">
                            <img src="@/assets/images/applet-sy3.png" alt="" />
                        </div>
                        <div class="applet-ds-text">
                            <div class="applet-ds-text-title">C2C分销电商</div>
                            <div class="applet-ds-text-desc">
                                平台提供多种分销渠道，PC端分销、微信分销、APP分销等众多渠道吸纳更多用户成为您的分销商，为平台更好的解决推广问题。
                            </div>
                        </div>
                    </div>
                    <div class="applet-ds-img-item">
                        <div class="applet-ds-img">
                            <img src="@/assets/images/applet-sy4.png" alt="" />
                        </div>
                        <div class="applet-ds-text">
                            <div class="applet-ds-text-title">全民拼团系统</div>
                            <div class="applet-ds-text-desc">
                                系统支持多种拼团玩法，商家可选择开启拼团模式，运营类拼多多模式商城，玩转粉丝流量。
                            </div>
                        </div>
                    </div>
                    <div class="applet-ds-img-item">
                        <div class="applet-ds-img">
                            <img src="@/assets/images/applet-sy5.png" alt="" />
                        </div>
                        <div class="applet-ds-text">
                            <div class="applet-ds-text-title">
                                C2B定制解决方案
                            </div>
                            <div class="applet-ds-text-desc">
                                由消费者决定自己个性化需求的多品种、小批量、快速反应的平台化协作。定制的背后有着商业化模式上的优美方程式，也就是说它是零库存，高溢价，高毛利。
                            </div>
                        </div>
                    </div>

                    <div class="applet-ds-img-item">
                        <div class="applet-ds-img">
                            <img src="@/assets/images/applet-sy6.png" alt="" />
                        </div>
                        <div class="applet-ds-text">
                            <div class="applet-ds-text-title">
                                B2C单商家商城
                            </div>
                            <div class="applet-ds-text-desc">
                                B2C商城系统，丰富的促销方式，个性化服务；支持微信支付、支付宝、银联、各大银行支付平台；支持申通、圆通、顺风、全峰、韵达等快递查询
                            </div>
                        </div>
                    </div>
                    <div class="applet-ds-img-item">
                        <div class="applet-ds-img">
                            <img src="@/assets/images/applet-sy7.png" alt="" />
                        </div>
                        <div class="applet-ds-text">
                            <div class="applet-ds-text-title">
                                F2C单商家商城
                            </div>
                            <div class="applet-ds-text-desc">
                                F2C模式是品牌公司把设计好的产品交由工厂代工后直接通过终端送达消费者，流通路径较短，这样可确保产品低价，同事质量服务都有保证。
                            </div>
                        </div>
                    </div>
                    <div class="applet-ds-img-item">
                        <div class="applet-ds-img">
                            <img src="@/assets/images/applet-sy8.png" alt="" />
                        </div>
                        <div class="applet-ds-text">
                            <div class="applet-ds-text-title">O2O商城系统</div>
                            <div class="applet-ds-text-desc">
                                O2O商城“线下”+“线上”无缝集成，服务性消费和非服务性消费区分；GPS区域性个性化等功能把网上和网下的优势准确结合。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="applet-dzkf">
            <div class="applet-dzkf-content">
                <div class="applet-dzkf-text">
                    <div class="applet-dzkf-text-title">
                        支持多种营销与互动功能深度定制开发
                    </div>
                    <div class="applet-dzkf-text-desc">
                        深度挖掘需求，助您打造智能商业体系，抢占微信社交红利
                    </div>
                </div>
                <div class="applet-dzkf-total">
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk1.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">微商城</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk2.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">会员系统</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk3.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">连锁平台</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk4.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">积分系统</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk5.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">微网站</div>
                    </div>
                    <div class="applet-dzkf-item clear-right">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk6.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">微活动</div>
                    </div>

                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk7.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">在线订餐</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk8.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">提交功能</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk9.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">线上交友</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk10.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">优惠券</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk11.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">定位导航</div>
                    </div>
                    <div class="applet-dzkf-item clear-right">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk12.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">在线预约</div>
                    </div>

                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk13.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">特色游戏</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk14.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">接口对接</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk15.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">场景应用</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk16.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">智能客服</div>
                    </div>
                    <div class="applet-dzkf-item">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk17.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">二维码管理</div>
                    </div>
                    <div class="applet-dzkf-item clear-right">
                        <div class="applet-dzkf-img">
                            <img src="@/assets/images/dzsk18.png" alt="" />
                        </div>
                        <div class="applet-dzkf-name">更多定制</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 行业覆盖 -->
        <div class="applet-hyfg">
            <div class="applet-hyfg-content">
                <div class="applet-hyfg-text">
                    <div class="applet-hyfg-text-title">
                        全行业覆盖 按需定制
                    </div>
                    <div class="applet-hyfg-text-desc">
                        服务全行业商家，为企业提供全套餐解决方案
                    </div>
                </div>

                <div class="applet-hyfg-total">
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg1.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">美容美发</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg2.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">教育培训</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg3.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">旅游度假</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg4.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">家居装修</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg5.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">汽车服务</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg6.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">家政服务</div>
                    </div>
                    <div class="applet-hyfg-item clear-right">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg7.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">鲜花蛋糕</div>
                    </div>

                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg8.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">餐饮外卖</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg9.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">休闲娱乐</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg10.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">奶茶咖啡</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg11.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">活动抽奖</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg12.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">珠宝首饰</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg13.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">母婴亲子</div>
                    </div>
                    <div class="applet-hyfg-item clear-right">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg14.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">服装鞋帽</div>
                    </div>

                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg15.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">电商分销</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg16.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">数码家电</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg17.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">社区团购</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg18.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">图书音像</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg19.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">医疗预约</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg20.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">五金建材</div>
                    </div>
                    <div class="applet-hyfg-item clear-right">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg21.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">宠物服务</div>
                    </div>

                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg22.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">美容养生</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg23.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">生鲜蔬果</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg24.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">网络招聘</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg25.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">同城商圈</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg26.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">电商零售</div>
                    </div>
                    <div class="applet-hyfg-item">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg27.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">二手交易</div>
                    </div>
                    <div class="applet-hyfg-item clear-right">
                        <div class="applet-hyfg-img">
                            <img src="@/assets/images/fyfg28.png" alt="" />
                        </div>
                        <div class="applet-hyfg-name">房产中介</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hz-lc">
            <div class="hz-lc-row">
                <div class="hz-title weight">9重保障 让您开发全程无忧</div>
            </div>
            <div class="row">
                <div class="hz-title-desc">
                    专家顾问企业私人量身打造，高效实战与高性价比的解决方案
                </div>
            </div>

            <div class="container-liuc">
                <div class="container-content">
                    <img id="liucheng" src="@/assets/images/hz1.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            timer: null
        }
    },
    methods: {
        setTime() {
            let num = 1
            this.timer = setInterval(function () {
                let src = $('#liucheng')[0].src
                let sub = src.substr(-14, 1)
                if (sub == 9) {
                    num = 1;
                } else {
                    num++;
                }
                $('#liucheng').attr('src', require('@/assets/images/hz' + num + '.png'))
            }, 1000)
        },
    },
    mounted() {
        this.setTime()
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>
<style scoped>
.services-banner {
    width: 100%;
    height: 550px;
    background: url("@/assets/images/xcx-bg.png") center center;
    background-size: auto 100%;
}

.applet-flow {
    width: 100%;
    height: 769px;
}
.applet-content {
    width: 1380px;
    height: 100%;
    margin: 0 auto;
    color: #333333;
    overflow: hidden;
}
.applet-text-content {
    width: 900px;
    text-align: center;
    margin: 0 auto;
}
.applet-text-title {
    font-size: 30px;
    margin-top: 64px;
    margin-bottom: 20px;
    font-weight: 500;
}
.applet-text-desc {
    color: #888888;
}
.applet-flow-total {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.applet-flow-item {
    width: 240px;
    text-align: center;
    margin-top: 63px;
}
.applet-flow-img {
    width: 60px;
    height: 60px;
    margin: 0 auto;
}
.applet-flow-img img {
    width: 100%;
}
.applet-ds {
    width: 100%;
    height: 1112px;
    background-color: #f5f8fa;
}
.applet-ds-content {
    width: 1380px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.applet-ds-text-content {
    width: 800px;
    margin: 105px auto 68px auto;
    text-align: center;
}
.applet-ds-title {
    font-size: 30px;
    font-weight: 500;
}
.applet-ds-desc {
    color: #888888;
    margin-top: 20px;
}
.applet-ds-img-total {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.applet-ds-img-item {
    width: 328px;
    height: 364px;
    background-color: #ffffff;
    margin-top: 24px;
}
.applet-ds-img-item img {
    width: 100%;
}
.applet-ds-text-title {
    width: 100%;
    font-size: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
}
.applet-ds-text-desc {
    width: 296px;
    text-align: left;
    color: #888888;
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
}
.applet-dzkf {
    width: 100%;
    height: 924px;
}
.applet-dzkf-content {
    width: 1380px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.applet-dzkf-text {
    width: 600px;
    text-align: center;
    margin: 0 auto;
    margin-top: 68px;
}
.applet-dzkf-text-title {
    font-size: 30px;
    font-weight: 500;
}
.applet-dzkf-text-desc {
    font-size: 18px;
    color: #888888;
    margin-top: 23px;
    margin-bottom: 67px;
}
.applet-dzkf-total {
    display: flex;
    flex-wrap: wrap;
}
.applet-dzkf-item {
    margin-top: 54px;
    margin-right: 134px;
    text-align: center;
}
.applet-dzkf-img {
    width: 118px;
    height: 118px;
}
.applet-dzkf-img img {
    width: 100%;
}
.clear-right {
    margin-right: 0 !important;
}
.applet-dzkf-name {
    font-size: 20px;
    margin-top: 20px;
}

.applet-hyfg {
    width: 100%;
    height: 886px;
    background: #464646;
}
.applet-hyfg-content {
    width: 1380px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.applet-hyfg-item {
    width: 67px;
    height: 106px;
}

.applet-hyfg-text {
    width: 500px;
    margin: 0 auto;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 21px;
}
.applet-hyfg-text-title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 23px;
    color: #ffffff;
}
.applet-hyfg-text-desc {
    font-size: 18px;
    color: #888888;
}
.applet-hyfg-total {
    display: flex;
    flex-wrap: wrap;
}
.applet-hyfg-item {
    margin-right: 147px;
    margin-top: 61px;
}
.applet-hyfg-name {
    font-size: 16px;
    color: #ffffff;
    margin-top: 14px;
}
.applet-hyfg-img {
    width: 67px;
    height: 67px;
}
.applet-hyfg-img img {
    width: 100%;
}

.hz-lc {
    width: 100%;
    background-color: #f5f8fa;
    height: 847px;
    overflow: hidden;
}
.hz-lc-row {
    width: 1380px;
    margin: 37px auto 23px auto;
}
.hz-title {
    color: #555555;
    font-size: 30px;
    text-align: center;
    margin-top: 37px;
}
.item-text-content {
    width: 289px;
    height: 202px;
}
.hz-title-desc {
    color: #888888;
    font-size: 18px;
    margin-top: 23px;
    text-align: center;
}
.hz-content {
    margin-top: 116px;
    display: flex;
    justify-content: space-between;
}
.container-liuc {
    width: 1380px;
    margin: 116px auto 0 auto;
}
.container-content {
    width: 100%;
    height: 495px;
}
.container-content img {
    width: 100%;
    height: 100%;
}
.weight {
    font-weight: 500;
}
</style>
