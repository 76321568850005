<template>
    <div>
        <!-- 服务范围 APP-->
        <div class="services-banner"></div>
        <!-- 为什么做自己的APP -->
        <div class="why-app">
            <div class="why-app-main content">
                <div class="why-app-text">
                    <div class="title-size why-app-title">
                        企业为什么要做自己的APP?
                    </div>
                    <div class="desc-size why-app-desc">
                        APP对于企业的作用绝不仅如此，其带来的效益超乎你的想象
                    </div>
                </div>

                <div class="flex-content">
                    <div class="why-app-item">
                        <div class="why-app-img">
                            <img src="@/assets/images/why-app1.png" alt="" />
                        </div>
                        <div class="why-app-item-title weight">创出好口碑</div>
                        <div class="why-app-desc">
                            通过微博、微信分享功能用户随时随地可把满意的体验分给他的朋友，创造更多的话题
                        </div>
                    </div>
                    <div class="why-app-item">
                        <div class="why-app-img">
                            <img src="@/assets/images/why-app2.png" alt="" />
                        </div>
                        <div class="why-app-item-title weight">
                            增加品牌互动
                        </div>
                        <div class="why-app-desc">
                            用户可以利用APP里面的评论区发表意见，也可以随时随地用图片或者文字向企业发表意见
                        </div>
                    </div>
                    <div class="why-app-item clear-right">
                        <div class="why-app-img">
                            <img src="@/assets/images/why-app3.png" alt="" />
                        </div>
                        <div class="why-app-item-title weight">
                            打造企业品牌
                        </div>
                        <div class="why-app-desc">
                            领先同行，率先做APP，令品牌差异化明显化营造公司创新形象
                        </div>
                    </div>
                    <div class="why-app-item">
                        <div class="why-app-img">
                            <img src="@/assets/images/why-app4.png" alt="" />
                        </div>
                        <div class="why-app-item-title weight">
                            节省企业的成本
                        </div>
                        <div class="why-app-desc">
                            省大量印刷的宣传费用，利用即时推送功能，精准通知客户
                        </div>
                    </div>
                    <div class="why-app-item">
                        <div class="why-app-img">
                            <img src="@/assets/images/why-app5.png" alt="" />
                        </div>
                        <div class="why-app-item-title weight">
                            精准目标客户群
                        </div>
                        <div class="why-app-desc">
                            用户下载企业的APP后，可按照会员的级别推送具有针对性的精准广告，提高目标客户的转化率
                        </div>
                    </div>
                    <div class="why-app-item clear-right">
                        <div class="why-app-img">
                            <img src="@/assets/images/why-app6.png" alt="" />
                        </div>
                        <div class="why-app-item-title weight">
                            强化购买意欲
                        </div>
                        <div class="why-app-desc">
                            产品以生动的文字图片和影片展示，清晰生动、分类准确、方便、快捷、易明，可连接更多资料，迅速积累客户，形成品牌依赖
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 没有自己app多可怕 -->
        <div class="why-dreadful">
            <div class="content">
                <div class="why-dreadul-text">
                    <div class="why-dreadul-title title-size">
                        一个企业如果没有自己的APP是多么可怕？
                    </div>
                    <div class="why-dreadul-desc desc-size">
                        网站大家都有，要在竞争对手中脱而出，而显著额手段就是拥有一款专属定制的APP
                    </div>
                </div>
                <div class="why-dreadul-total flex-content">
                    <div class="why-dreadul-item">
                        <div class="why-dreadul-img">
                            <img src="@/assets/images/dreadful1.png" alt="" />
                        </div>
                        <div class="why-dreadul-item-title">手机用户7.88亿</div>
                        <div class="why-dreadul-item-desc">
                            根据中国互联网信息中心第42次调查报告截止2018年6月，中国网民规模达到8.02亿，互联网普及率为57.7%，手机网民规模为7.88亿占比提升到98.3%。
                        </div>
                    </div>
                    <div class="why-dreadul-item">
                        <div class="why-dreadul-img">
                            <img src="@/assets/images/dreadful2.png" alt="" />
                        </div>
                        <div class="why-dreadul-item-title">
                            使用APP用户达79%
                        </div>
                        <div class="why-dreadul-item-desc">
                            据调查，每天早上醒来后高达79%的用户会打开手机APP，手机APP已经成为人们的一大日常必需品，越来越多的人从手机APP获取资讯、娱乐和网上购物。
                        </div>
                    </div>
                    <div class="why-dreadul-item clear-right">
                        <div class="why-dreadul-img">
                            <img src="@/assets/images/dreadful3.png" alt="" />
                        </div>
                        <div class="why-dreadul-item-title">
                            1/3的企业有自己的APP
                        </div>
                        <div class="why-dreadul-item-desc">
                            据不完全统计，目前已有1/3的国内企业定制了自己专属的企业APP，且还在持续增加，国内企业APP定制和应用已快速渗透到零售业、媒体门户等诸多热门领域。
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="why-customized">
            <div class="content">
                <div class="why-cust-text">
                    <div class="why-cust-text">
                        <div class="title-size">APP定制开发</div>
                        <div class="why-cust-text-desc desc-size">
                            我们擅长设计与开发高质量的移动应用（APP）。进行移动APP开发时，从产品设计到达成商业目标，用户
                            体验设计到技术研发，产品运营与后期维护，我们可以为您提供一站式服务。
                        </div>
                    </div>

                    <div class="shebei">
                        <div class="shebei-item">
                            <div class="shebei-item-img">
                                <img
                                    src="@/assets/images/ios-icon.png"
                                    alt=""
                                />
                            </div>
                            <div class="shebei-item-title weight">ios开发</div>
                            <div class="shebei-item-desc">
                                我们拥有国内顶级的iOS开发团队，为您提供一站式的手机APP定制开发服务
                            </div>
                        </div>
                        <div class="shebei-item">
                            <div class="shebei-item-img">
                                <img
                                    src="@/assets/images/android-icon.png"
                                    alt=""
                                />
                            </div>
                            <div class="shebei-item-title weight">
                                Andriod开发
                            </div>
                            <div class="shebei-item-desc">
                                我们擅长各种安卓手机APP应用开发，确保客户产品的商业价值
                            </div>
                        </div>
                        <div class="shebei-item">
                            <div class="shebei-item-img">
                                <img
                                    src="@/assets/images/pad-icon.png"
                                    alt=""
                                />
                            </div>
                            <div class="shebei-item-title weight">Pad</div>
                            <div class="shebei-item-desc">
                                我们擅长各种安卓手机APP应用开发，确保客户产品的商业价值
                            </div>
                        </div>
                    </div>
                    <div class="tuandui">
                        <div class="tuandui-left">
                            <div>产品设计团队</div>
                            <div>产品创意</div>
                            <div>方案设计</div>
                            <div>产品设计</div>
                        </div>
                        <div class="tuandui-center">
                            <img src="@/assets/images/cust1.png" alt="" />
                        </div>
                        <div class="tuandui-right">
                            <div>技术开发团队</div>
                            <div>Android研发团队</div>
                            <div>微信研发团队</div>
                            <div>后台研发团队</div>
                            <div>运营维护团队</div>
                        </div>
                    </div>
                    <div class="bingfa">
                        <div class="fangan-title">高并发设计</div>
                        <div>
                            <img src="@/assets/images/cust2.png" alt="" />
                        </div>
                    </div>
                    <div class="fangan">
                        <div class="fangan-title">部署方案</div>
                        <div>
                            <img src="@/assets/images/cust3.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hz-lc">
            <div class="hz-lc-row">
                <div class="hz-title weight">9重保障 让您开发全程无忧</div>
            </div>
            <div class="row">
                <div class="hz-title-desc">
                    专家顾问企业私人量身打造，高效实战与高性价比的解决方案
                </div>
            </div>

            <div class="container-liuc">
                <div class="container-content">
                    <img id="liucheng" src="@/assets/images/hz1.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            timer: null
        }
    },
    methods: {
        setTime() {
            let num = 1
            this.timer = setInterval(function () {
                let src = $('#liucheng')[0].src
                let sub = src.substr(-14, 1)
                if (sub == 9) {
                    num = 1;
                } else {
                    num++;
                }
                $('#liucheng').attr('src', require('@/assets/images/hz' + num + '.png'))
            }, 1000)
        },
    },
    mounted() {
        this.setTime()
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>
<style scoped>
.services-banner {
    width: 100%;
    height: 550px;
    background: url("@/assets/images/service-app.png") center center;
    background-size: auto 100%;
}

.flex-content {
    display: flex;
    flex-wrap: wrap;
}

/* 为什么做app */
.why-app {
    width: 100%;
    height: 769px;
}
.content {
    width: 1380px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.title-size {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
}
.desc-size {
    font-size: 18px;
    text-align: center;
}

.why-app-text {
    margin-top: 64px;
    margin-bottom: 18px;
}

.why-app-desc {
    margin-top: 24px;
}
.why-app-item {
    width: 311px;
    margin-right: 209px;
    margin-top: 39px;
}
.clear-right {
    margin-right: 0 !important;
}
.why-app-img,
.why-app-item-title {
    margin: 0 auto;
}

.why-app-img {
    width: 74px;
    height: 74px;
    margin: 0 auto;
}
.why-app-img img {
    width: 100%;
}
.why-app-item-title {
    width: 100px;
    margin-top: 36px;
    text-align: center;
}
.why-app-desc {
    font-size: 14px;
}
.flex-conten {
    display: flex;
    flex-wrap: wrap;
}

/* 没有自己的app多可怕 */
.why-dreadful {
    width: 100%;
    height: 730px;
    background-image: url("@/assets/images/why-dreadful.png");
    background-repeat: no-repeat;
    background-size: 100%;
}
.why-dreadul-text {
    color: #ffffff;
    margin-top: 65px;
    margin-bottom: 24px;
}
.why-dreadul-desc {
    margin-top: 24px;
}
.why-dreadul-item {
    width: 437px;
    text-align: center;
    color: #ffffff;
    margin-right: 34px;
}
.why-dreadul-item-title {
    margin-top: 30px;
    margin-bottom: 23px;
    font-size: 24px;
    font-weight: 500;
}

.why-customized {
    width: 100%;
    height: 2328px;
}
.why-cust-text {
    margin-top: 90px;
}
.why-cust-text-desc {
    width: 844px;
    color: #888888;
    margin: 23px auto 78px auto;
}
.shebei {
    display: flex;
    justify-content: space-between;
}
.shebei-item {
    width: 327px;
    text-align: center;
}
.shebei-item-title {
    margin-top: 26px;
    margin-bottom: 20px;
    font-size: 24px;
}
.shebei-item-desc {
    color: #333333;
    font-size: 18px;
}
.fangan-title {
    color: #555555;
    font-size: 30px;
    margin-bottom: 42px;
}

.tuandui {
    display: flex;
    margin-top: 100px;
    margin-bottom: 28px;
    justify-content: space-between;
    line-height: 40px;
    font-size: 18px;
}
.tuandui-left {
    width: 191px;
    text-align: right;
}
.tuandui-left div:nth-child(1),
.tuandui-right div:nth-child(1) {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
}
.tuandui-right {
    width: 191px;
    text-align: left;
    margin-top: 127px;
}

.hz-lc {
    width: 100%;
    background-color: #f5f8fa;
    height: 847px;
    overflow: hidden;
}
.hz-lc-row {
    width: 1380px;
    margin: 37px auto 23px auto;
}
.hz-title {
    color: #555555;
    font-size: 30px;
    text-align: center;
    margin-top: 37px;
}
.item-text-content {
    width: 289px;
    height: 202px;
}
.hz-title-desc {
    color: #888888;
    font-size: 18px;
    margin-top: 23px;
    text-align: center;
}
.hz-content {
    margin-top: 116px;
    display: flex;
    justify-content: space-between;
}
.container-liuc {
    width: 1380px;
    margin: 116px auto 0 auto;
}
.container-content {
    width: 100%;
    height: 495px;
}
.container-content img {
    width: 100%;
    height: 100%;
}
.weight {
    font-weight: 500;
}
</style>
