<template>
    <div>
        <div class="footer">
            <div class="footer-main">
                <div class="footer-one">
                    <div class="footer-left-main">
                        <div class="footer-logo">
                            <img src="@/assets/images/footer-logo.png" alt="" />
                        </div>
                        <div class="footer-left">
                            <div class="footer-left-item">
                                <div>联系业务</div>
                                <div class="left-img">
                                    <img
                                        src="@/assets/images/aisiyw.jpeg"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div class="footer-left-item">
                                <div>艾思官网</div>
                                <div class="left-img">
                                    <img src="@/assets/images/h5.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="footer-center-main">
                        <div class="footer-center-left">
                            <div class="cl-title">产品与服务</div>
                            <div class="center-xian"></div>
                            <div class="center-desc">
                                <div class="center-text">
                                    <a href="/wechat" target="_blank"
                                        >微信开发</a
                                    >
                                </div>
                                <div class="center-text">
                                    <a href="/applet" target="_blank"
                                        >小程序开发</a
                                    >
                                </div>
                                <div class="center-text">
                                    <a href="/app" target="_blank">APP开发</a>
                                </div>
                                <div class="center-text">
                                    <a href="/web" target="_blank">网站开发</a>
                                </div>
                            </div>
                        </div>
                        <div class="footer-center-center">
                            <div class="cl-title">行业解决方案</div>
                            <div class="center-xian"></div>
                            <div class="center-desc">
                                <div class="center-text">
                                    <a href="/msn" target="_blank"
                                        >即时通讯APP</a
                                    >
                                </div>
                                <div class="center-text">
                                    <a href="/edu" target="_blank"
                                        >教育APP软件</a
                                    >
                                </div>
                                <div class="center-text">
                                    <a href="/oa" target="_blank">ERP/OA办公</a>
                                </div>
                                <div class="center-text">
                                    <a href="/live" target="_blank"
                                        >视频直播APP</a
                                    >
                                </div>
                                <div class="center-text">
                                    <a href="/shop" target="_blank"
                                        >电商解决方案</a
                                    >
                                </div>
                                <div class="center-text">
                                    <a href="/hard" target="_blank">物联网</a>
                                </div>
                            </div>
                        </div>

                        <div class="footer-center-center">
                            <div class="cl-title">联系我们</div>
                            <div class="center-xian"></div>
                            <div class="center-desc">
                                <div class="center-text">
                                    售前电话: 13365392580
                                </div>
                                <div class="center-text">
                                    客服电话: 13365392580
                                </div>
                                <div class="center-text">
                                    售后电话: 18254947265
                                </div>
                                <div class="center-text">
                                    邮箱: wangzhiliang#aisisoft.cn
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="footer-center-right">
                        <div>24小时客服服务</div>
                        <div class="footer-phone">13365392580</div>
                        <div>
                            <div style="margin: 8px auto" class="left-img">
                                <img
                                    src="@/assets/images/aisigzh.jpeg"
                                    alt=""
                                />
                            </div>
                            <div>艾思公众号</div>
                        </div>
                    </div>
                </div>
                <div class="footer-two">
                    <div class="two-title">友情链接：</div>
                    <div class="two-center">
                        <span v-for="(item, index) in friendsList" :key="index"
                            ><a :href="item.web_url" target="_blank">{{ item.title }}</a></span
                        >
                    </div>
                </div>
                <div class="footer-bottom">
                    ©2022 山东艾思软件科技有限公司
                    <a href="https://beian.miit.gov.cn/" target="_blank"
                        ><span style="color: #fff"
                            >鲁ICP备2022019256号-2</span
                        ></a
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getHomeData } from '@/api/index/index'
export default {
    data() {
        return {
            friendsList: []
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            getHomeData().then(res => {
                this.friendsList = res.data.friends
            })
        }
    }
}
</script>
<style scoped>
.footer {
    width: 100%;
    background-color: #1f1f1f;
    height: 469px;
    overflow: hidden;
}
.footer-main {
    width: 1380px;
    height: 100%;
    margin: 0 auto;
}
.footer-one {
    display: flex;
    margin-top: 38px;
    height: 284px;
    border-bottom: 1px solid #333333;
}
.footer-logo {
    width: 259px;
    height: 53px;
}
.footer-logo img {
    width: 80%;
}
.footer-left-main {
    width: 312px;
    height: 245px;
    border-right: 1px solid #333333;
}
.footer-left {
    width: 259px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #ffffff;
    margin-top: 39px;
}
.footer-left-item {
    width: 114px;
    text-align: center;
}
.left-img {
    width: 114px;
    height: 114px;
    background-color: #ffffff;
    margin-top: 17px;
}
.left-img img {
    width: 100%;
}
.footer-center-main {
    width: 770px;
    height: 245px;
    display: flex;
    justify-content: space-around;
    border-right: 1px solid #333333;
}

.cl-title {
    font-size: 16px;
    color: #ffffff;
}
.center-xian {
    width: 115px;
    height: 1px;
    background-color: #333333;
    margin: 0 auto;
    margin-top: 6px;
}
.center-desc {
    text-align: left;
    margin-top: 21px;
    margin-left: 20px;
}
.center-desc a {
    color: #888888;
}
.center-desc a:hover {
    color: #eee;
}
.center-text {
    color: #888888;
    font-size: 14px;
    margin-top: 16px;
}
.footer-center-right {
    width: 192px;
    height: 245px;
    margin-left: 36px;
    text-align: center;
    color: #ffffff;
}
.footer-phone {
    font-size: 30px;
}
.footer-two {
    width: 100%;
    height: 63px;
    border-bottom: 1px solid #333333;
    color: #888888;
    font-size: 12px;
    display: flex;
    padding-top: 24px;
}
.two-title {
    width: 80px;
    font-size: 14px;
}
.two-center a {
    color: #888888;
    font-size: 12px;
}
.two-center span {
    margin-left: 20px;
    line-height: 20px;
}
.footer-bottom {
    text-align: center;
    color: #888888;
    font-size: 12px;
    margin-top: 18px;
}
</style>