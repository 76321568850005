<template>
    <div>
        <div class="company"></div>

        <div class="news">
            <div class="news-conten">
                <div class="news-left">
                    <div class="news-title">
                        <div class="title-item">
                            <a href="javascript:void(0)" @click="getNews(0)"
                                >全部</a
                            >
                        </div>
                        <div class="title-item">
                            <a href="javascript:void(0)" @click="getNews(1)"
                                >知识/咨询</a
                            >
                        </div>
                        <div class="title-item">
                            <a href="javascript:void(0)" @click="getNews(2)"
                                >艾思相关</a
                            >
                        </div>
                    </div>

                    <div class="left-lists">
                        <div
                            class="left-list-item"
                            v-for="(item, index) in lists"
                            :key="index"
                        >
                            <div
                                class="list-item-left"
                                style="cursor: pointer"
                                @click="toPage('/detail', item.id)"
                            >
                                <img :src="item.img_url" alt="" />
                            </div>
                            <div class="list-item-right">
                                <div
                                    class="list-item-right-top"
                                    style="cursor: pointer"
                                    @click="toPage('/detail', item.id)"
                                >
                                    {{ item.title }}
                                </div>
                                <div class="list-item-right-center">
                                    {{ item.desc }}
                                </div>
                                <div class="list-item-right-bottom">
                                    <div>
                                        {{ item.create_time }}
                                    </div>
                                    <div
                                        class="bottom-tags"
                                        v-if="item.tags[0].length"
                                    >
                                        <span
                                            v-for="(value, key) in item.tags"
                                            :key="key"
                                            >{{ value }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :total="count"
                    >
                    </el-pagination>
                </div>
                <div class="news-right">
                    <div>
                        <div class="news-right-title">动态推荐</div>
                        <div class="news-right-lists">
                            <div
                                v-for="(item, index) in limitNew"
                                :key="index"
                                class="news-right-item"
                            >
                                <a :href="'/detail?id=' + item.id"
                                    >{{ index + 1 }}、{{ item.title }}</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getAllNews, limitData } from '@/api/index/index'
import router from '@/router'
export default {
    data() {
        return {
            page: 1,
            page_size: 8,
            count: 0,
            lists: [],
            limitNew: []
        }
    },
    created() {
        this.getNews()
        this.getLimitNew()
    },
    methods: {
        toPage(url, id) {
            router.push({
                path: url, query: {
                    id: id
                }
            })
        },
        handleCurrentChange(val) {
            this.page = val
            this.getNews()
        },
        getNews(id = 0) {
            getAllNews({ page: this.page, page_size: this.page_size, category_id: id }).then(res => {
                this.count = res.data.count
                this.lists = res.data.data
                this.lists.forEach(element => {
                    element.tags = element['tags'].split(',');
                });
            })
        },
        getLimitNew() {
            limitData({ limit: 10 }).then(res => {
                this.limitNew = res.data
            })
        },

    }
}
</script>
<style scoped>
.company {
    width: 100%;
    height: 400px;
    background: url("@/assets/images/company-banner.png") center center;
    background-size: 100% auto;
}

.news {
    width: 100%;
    margin-bottom: 40px;
}
.news-conten {
    width: 1380px;
    margin: 0 auto;
    display: flex;
}
.news-left {
    width: 935px;
    position: relative;
    height: 1600px;
}
::v-deep .el-pagination {
    margin-top: 60px;
}
.news-title {
    width: 100%;
    height: 82px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
}
.title-item {
    margin-right: 100px;
    height: 78px;
    line-height: 82px;
}
.title-item a {
    color: #555555;
    font-size: 18px;
}
.title-item a:hover {
    color: #0075ea;
    font-weight: 400;
}
.title-item:hover {
    border-bottom: 4px solid #0075ea;
}
.left-list-item {
    display: flex;
    padding-top: 16px;
    padding-bottom: 14px;
    border-bottom: 1px solid #d9d9d9;
}
.list-item-right {
    width: 100%;
    margin-left: 17px;
    text-align: left;
}
.list-item-right-top {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
}
.list-item-right-top:hover {
    color: #0075ea;
}
.list-item-right-center {
    width: 650px;
    color: #888888;
    font-size: 16px;
    margin-top: 14px;
    margin-bottom: 14px;
}
.list-item-right-bottom {
    display: flex;
    justify-content: space-between;
}
.bottom-tags {
    position: relative;
}

.bottom-tags span {
    border: 1px solid #e9e9e9;
    padding: 2px 8px;
    margin: 0px 5px;
    border-radius: 10px;
    background: #f8f8f8;
    color: #888888;
    font-size: 14px;
}

.news-right-title {
    font-size: 18px;
    height: 78px;
    font-weight: 500;
    color: #000000;
    margin-left: 68px;
    line-height: 82px;
    border-bottom: 4px solid #0075ea;
}
.news-right-item {
    color: #555555;
}
.news-right-item a {
    color: #555555;
}
.news-right-item a:hover {
    color: #0075ea;
}
.news-right-lists {
    text-align: left;
    margin-left: 68px;
    margin-top: 17px;
    line-height: 26px;
}
.list-item-left img {
    width: 196px;
    height: 134px;
}
.list-item-left img:hover {
    opacity: 0.9;
}
</style>
