<template>
    <div>
        <!-- 轮播图 -->
        <div class="lunbo">
            <div class="lunbo-row">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="(item, index) in bannerList"
                            :key="index"
                        >
                            <img :src="item.img_url" alt="" />
                        </div>
                        <!-- <div class="swiper-slide"><img src="http://aisisoft.cn/static/index/images/banner1.png" alt=""></div> -->
                        <!-- <div class="swiper-slide"><img src="http://aisisoft.cn/static/index/images/banner2.png" alt=""></div> -->
                    </div>
                    <div class="swiper-pagination"></div>
                    <div
                        class="
                            swiper-button-prev
                            swiper-button-prev
                            swiper-button-white
                        "
                    ></div>
                    <div
                        class="
                            swiper-button-next
                            swiper-button-next
                            swiper-button-white
                        "
                    ></div>
                </div>
            </div>
        </div>

        <!-- 服务项目 -->
        <div class="container" id="service-project">
            <div class="service-project-row">
                <div
                    class="
                        col-md-4 col-md-offset-4
                        service-project-title
                        weight
                    "
                >
                    服务项目/Service Items
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-md-offset-4 service-project-desc">
                    专业团队研发精品软件
                </div>
            </div>
            <div class="row service-content">
                <div class="service-content-left">
                    <div class="service-left-item">
                        <div class="letf-item-title">
                            <div class="letf-item-title-h3">管理系统定制</div>
                            <div class="left-item-title-desc">
                                需求分析-创意设计-资料添加-测试交付每一步我们都追求精致
                            </div>
                        </div>
                        <div class="project-content">
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(1)"
                                class="
                                    project-item
                                    item-shu-xian item-heng-xian
                                "
                            >
                                <div class="item-img" v-show="index == 1">
                                    <img
                                        src="@/assets/images/fu1-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 1">
                                    <img src="@/assets/images/fu1.png" alt="" />
                                </div>
                                <div>医疗问诊平台</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(2)"
                                class="project-item item-heng-xian"
                            >
                                <div class="item-img" v-show="index == 2">
                                    <img
                                        src="@/assets/images/fu2-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 2">
                                    <img src="@/assets/images/fu2.png" alt="" />
                                </div>
                                <div>医疗供应链系统</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(3)"
                                class="project-item item-shu-xian"
                            >
                                <div class="item-img" v-show="index == 3">
                                    <img
                                        src="@/assets/images/fu3-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 3">
                                    <img src="@/assets/images/fu3.png" alt="" />
                                </div>
                                <div>在线教育系统</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(4)"
                                class="project-item"
                            >
                                <div class="item-img" v-show="index == 4">
                                    <img
                                        src="@/assets/images/fu4-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 4">
                                    <img src="@/assets/images/fu4.png" alt="" />
                                </div>
                                <div>电商直播平台</div>
                            </div>
                        </div>
                    </div>
                    <div class="left-xian"></div>
                    <div class="service-left-item">
                        <div class="letf-item-title">
                            <div class="letf-item-title-h3">微信开发</div>
                            <div class="left-item-title-desc">
                                基于微信为企业提供开发、运营、培训、推广一体化解决方案，帮助企业实现，线上线下互通
                            </div>
                        </div>
                        <div class="project-content">
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(5)"
                                class="
                                    project-item
                                    item-shu-xian item-heng-xian
                                "
                            >
                                <div class="item-img" v-show="index == 5">
                                    <img
                                        src="@/assets/images/fu5-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 5">
                                    <img src="@/assets/images/fu5.png" alt="" />
                                </div>
                                <div>酒店旅游平台</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(6)"
                                class="project-item item-heng-xian"
                            >
                                <div class="item-img" v-show="index == 6">
                                    <img
                                        src="@/assets/images/fu6-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 6">
                                    <img src="@/assets/images/fu6.png" alt="" />
                                </div>
                                <div>社交电商系统</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(7)"
                                class="project-item item-shu-xian"
                            >
                                <div class="item-img" v-show="index == 7">
                                    <img
                                        src="@/assets/images/fu7-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 7">
                                    <img src="@/assets/images/fu7.png" alt="" />
                                </div>
                                <div>O2O预约系统</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(8)"
                                class="project-item"
                            >
                                <div class="item-img" v-show="index == 8">
                                    <img
                                        src="@/assets/images/fu8-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 8">
                                    <img src="@/assets/images/fu8.png" alt="" />
                                </div>
                                <div>CRM系统</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="shu-xian"></div>

                <div class="service-content-right">
                    <div class="service-left-item">
                        <div class="letf-item-title">
                            <div class="letf-item-title-h3">APP开发</div>
                            <div class="left-item-title-desc">
                                完全个性化设计，专属定制研发应用软件，安全稳定的技术运维服务，为您提供多方位解决方案
                            </div>
                        </div>
                        <div class="project-content">
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(9)"
                                class="
                                    project-item
                                    item-shu-xian item-heng-xian
                                "
                            >
                                <div class="item-img" v-show="index == 9">
                                    <img
                                        src="@/assets/images/fu9-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 9">
                                    <img src="@/assets/images/fu9.png" alt="" />
                                </div>
                                <div>HTML5开发</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(10)"
                                class="project-item item-heng-xian"
                            >
                                <div class="item-img" v-show="index == 10">
                                    <img
                                        src="@/assets/images/fu10-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 10">
                                    <img
                                        src="@/assets/images/fu10.png"
                                        alt=""
                                    />
                                </div>
                                <div>安卓APP开发</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(11)"
                                class="project-item item-shu-xian"
                            >
                                <div class="item-img" v-show="index == 11">
                                    <img
                                        src="@/assets/images/fu11-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 11">
                                    <img
                                        src="@/assets/images/fu11.png"
                                        alt=""
                                    />
                                </div>
                                <div>iOS APP开发</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(12)"
                                class="project-item"
                            >
                                <div class="item-img" v-show="index == 12">
                                    <img
                                        src="@/assets/images/fu12-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 12">
                                    <img
                                        src="@/assets/images/fu12.png"
                                        alt=""
                                    />
                                </div>
                                <div>WEB开发</div>
                            </div>
                        </div>
                    </div>
                    <div class="left-xian"></div>
                    <div class="service-left-item">
                        <div class="letf-item-title">
                            <div class="letf-item-title-h3">小程序开发</div>
                            <div class="left-item-title-desc">
                                根据企业行业特点和自身需求整合企业网络资源提供长期的小程序开发服务支持
                            </div>
                        </div>
                        <div class="project-content">
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(13)"
                                class="
                                    project-item
                                    item-shu-xian item-heng-xian
                                "
                            >
                                <div class="item-img" v-show="index == 13">
                                    <img
                                        src="@/assets/images/fu13-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 13">
                                    <img
                                        src="@/assets/images/fu13.png"
                                        alt=""
                                    />
                                </div>
                                <div>智慧物业系统</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(14)"
                                class="project-item item-heng-xian"
                            >
                                <div class="item-img" v-show="index == 14">
                                    <img
                                        src="@/assets/images/fu14-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 14">
                                    <img
                                        src="@/assets/images/fu14.png"
                                        alt=""
                                    />
                                </div>
                                <div>医美平台开发</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(15)"
                                class="project-item item-shu-xian"
                            >
                                <div class="item-img" v-show="index == 15">
                                    <img
                                        src="@/assets/images/fu15-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 15">
                                    <img
                                        src="@/assets/images/fu15.png"
                                        alt=""
                                    />
                                </div>
                                <div>智能家居系统</div>
                            </div>
                            <div
                                @mouseout="projectOver(0)"
                                @mouseover="projectOver(16)"
                                class="project-item"
                            >
                                <div class="item-img" v-show="index == 16">
                                    <img
                                        src="@/assets/images/fu16-s.png"
                                        alt=""
                                    />
                                </div>
                                <div class="item-img" v-show="index != 16">
                                    <img
                                        src="@/assets/images/fu16.png"
                                        alt=""
                                    />
                                </div>
                                <div>智慧停车系统</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 为什么选择我们 -->
        <div class="why">
            <div class="why-row">
                <div class="col-md-4 col-md-offset-4 why-title weight">
                    为什么选择我们？
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-md-offset-4 why-title-desc">
                    来艾思软件，轻松解决您的顾虑，让您的企业不再烦恼
                </div>
            </div>
            <div class="container why-content">
                <div class="why-content-item">
                    <div>
                        <img
                            class="img-responsive"
                            src="@/assets/images/why1.png"
                            alt=""
                        />
                    </div>
                    <div class="item-text">免费售前解决方案一流</div>
                    <div>案例业内多</div>
                </div>
                <div class="why-content-item">
                    <div>
                        <img
                            class="img-responsive"
                            src="@/assets/images/why2.png"
                            alt=""
                        />
                    </div>
                    <div class="item-text">官方签约</div>
                    <div>上线可靠保障</div>
                </div>
                <div class="why-content-item">
                    <div>
                        <img
                            class="img-responsive"
                            src="@/assets/images/why3.png"
                            alt=""
                        />
                    </div>
                    <div class="item-text">高性价比</div>
                    <div>价格合理</div>
                </div>
                <div class="why-content-item">
                    <div>
                        <img
                            class="img-responsive"
                            src="@/assets/images/why4.png"
                            alt=""
                        />
                    </div>
                    <div class="item-text">开发工期 行业短</div>
                    <div>免费售后 周期长</div>
                </div>
                <div class="why-content-item">
                    <div>
                        <img
                            class="img-responsive"
                            src="@/assets/images/why5.png"
                            alt=""
                        />
                    </div>
                    <div class="item-text">合作两周内</div>
                    <div>不满意 随时退款</div>
                </div>
            </div>
        </div>

        <!-- 经典案例 -->
        <div class="anli">
            <div class="anli-row">
                <div class="anli-title weight">经典案例/Case Show</div>
            </div>
            <div class="row">
                <div class="anli-title-desc anli-title-top">
                    累计为超过2000家客户提供互联网解决或咨询服务，包含国际知名企业；
                </div>
                <div class="col-md-8 col-md-offset-2 anli-title-desc">
                    涉及：金融、地产、物业、制造、家居、营销、传媒文化、餐饮、电子科技数码等多个行业
                </div>
            </div>
            <div class="anli-content">
                <div class="col-md-12 anli-row anli-row-one">
                    <div
                        class="col-md-4 anli-row-item"
                        @click="toDetail(item.id)"
                        v-for="(item, index) in productsList"
                        :key="index"
                    >
                        <div class="anli-row-img">
                            <img
                                class="img-responsive"
                                :src="item.img_url"
                                alt=""
                            />
                        </div>
                        <div class="anli-row-text">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 合作流程 -->
        <div class="hz-lc">
            <div class="hz-lc-row">
                <div class="hz-title weight">合作流程/Cooperation Flow</div>
            </div>
            <div class="row">
                <div class="hz-title-desc">
                    专家顾问企业私人量身打造，高效实战与高性价比的解决方案
                </div>
            </div>

            <div class="container">
                <div class="container-content">
                    <div class="cooperation_position_rel">
                        <div
                            class="cooperation_position_abs cooperation_1"
                            @mouseover="cooperation_over(1)"
                            @mouseleave="setTime(1)"
                        ></div>
                        <div
                            class="cooperation_position_abs cooperation_2"
                            @mouseover="cooperation_over(2)"
                            @mouseleave="setTime(2)"
                        ></div>
                        <div
                            class="cooperation_position_abs cooperation_3"
                            @mouseover="cooperation_over(3)"
                            @mouseleave="setTime(3)"
                        ></div>
                        <div
                            class="cooperation_position_abs cooperation_4"
                            @mouseover="cooperation_over(4)"
                            @mouseleave="setTime(4)"
                        ></div>
                        <div
                            class="cooperation_position_abs cooperation_5"
                            @mouseover="cooperation_over(5)"
                            @mouseleave="setTime(5)"
                        ></div>
                        <div
                            class="cooperation_position_abs cooperation_6"
                            @mouseover="cooperation_over(6)"
                            @mouseleave="setTime(6)"
                        ></div>
                        <div
                            class="cooperation_position_abs cooperation_7"
                            @mouseover="cooperation_over(7)"
                            @mouseleave="setTime(7)"
                        ></div>
                        <div
                            class="cooperation_position_abs cooperation_8"
                            @mouseover="cooperation_over(8)"
                            @mouseleave="setTime(8)"
                        ></div>
                        <div
                            class="cooperation_position_abs cooperation_9"
                            @mouseover="cooperation_over(9)"
                            @mouseleave="setTime(9)"
                        ></div>
                    </div>

                    <img id="liucheng" src="@/assets/images/hz1.png" alt="" />
                </div>
            </div>
        </div>

        <!-- 艾思动态 -->
        <div class="dt">
            <div class="dt-row">
                <div class="dt-title weight">艾思动态/Ais News</div>
            </div>
            <div class="dt-row">
                <div class="col-md-6 col-md-offset-3 dt-title-desc">
                    关注艾思，了解艾思，让我们一起见证每一个精彩瞬间
                </div>
            </div>

            <div class="dt-content">
                <div
                    class="dt-content-item"
                    v-for="(item, index) in newsList"
                    :key="index"
                >
                    <div
                        @click="toNewDetail(item.id)"
                        style="cursor: pointer; pointer-events: auto"
                    >
                        <img :src="item.img_url" alt="" />
                    </div>
                    <div class="dt-item-right">
                        <div
                            class="item-right-title"
                            style="cursor: pointer; pointer-events: auto"
                            @click="toNewDetail(item.id)"
                        >
                            {{ item.title }}
                        </div>
                        <div class="item-right-center">{{ item.desc }}</div>
                        <div class="dt-item-right-bottom">
                            <div>{{ item.create_time }}</div>
                            <div
                                class="dt-item-detail"
                                @click="toNewDetail(item.id)"
                            >
                                查看详情
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 联系我们 -->
        <div class="lxwm">
            <div class="lxwm-row">
                <div class="col-md-12 lxwm-title">
                    我们是以技术手段推动社会进步发展的新型电子商务公司
                </div>
            </div>
            <div class="lxwm-row">
                <div class="col-md-12 lxwm-text">
                    如果您需要我们帮助，可以在线联系，我们一定第一时间为您解决问题
                </div>
            </div>
            <div class="lxwm-row">
                <div class="lxwm-botton-content">
                    <a
                        class="lxwm-button"
                        id="lx"
                        href="javascript:void(0)"
                        onclick="_MEIQIA('showPanel')"
                        ><span>联系我们</span></a
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderView from '@/components/HeaderView.vue'
import FooterView from '@/components/FooterView.vue'
import { getHomeData } from '@/api/index/index'
import $ from 'jquery'
import router from '@/router'
export default {
    components: {
        HeaderView,
        FooterView
    },
    data() {
        return {
            timer: null,
            index: 0,
            bannerList: [],
            partnerList: [],
            productsList: [],
            newsList: []
        }
    },
    created() {
        this.getData()
        console.log()
    },
    methods: {
        getData() {
            getHomeData().then(res => {
                this.bannerList = res.data.banner
                this.productsList = res.data.products
                this.newsList = res.data.news
                console.log(1111)
            })

        },
        toDetail(id) {
            router.push({
                path: '/productDetail', query: {
                    id: id
                }
            })
        },
        initSwiper() {
            setTimeout(() => {
                new Swiper('.swiper-container', {
                    autoplay: 2000,//可选选项，自动滑动
                    prevButton: '.swiper-button-prev',
                    nextButton: '.swiper-button-next',
                    pagination: '.swiper-pagination',
                })
            }, 1000)

        },
        setTime(num = 1) {
            this.timer = setInterval(function () {
                let src = $('#liucheng')[0].src
                let sub = src.substr(-14, 1)
                if (sub >= 9) {
                    num = 1;
                } else {
                    num++;
                }
                $('#liucheng').attr('src', require('@/assets/images/hz' + num + '.png'))
            }, 1000)
        },
        projectOver(num) {
            this.index = num
        },

        returnEnevt(e) {
            e.stopPropagation()
        },
        toNewDetail(id) {
            router.push({
                path: '/detail',
                query: {
                    id: id
                }
            })
        },
        cooperation_over(num) {
            clearInterval(this.timer);
            $('#liucheng').attr('src', require('@/assets/images/hz' + num + '.png'))
        },


    },
    mounted() {

        this.initSwiper()
        this.setTime()
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    },

}
</script>

<style scoped>
.cooperation_position_rel {
    position: relative;
}
.cooperation_position_abs {
    position: absolute;
    /* border: 1px solid red; */
    height: 200px;
    width: 240px;
}
.cooperation_1 {
    left: 0;
    top: 0;
}
.cooperation_2 {
    left: 140px;
    top: 290px;
}
.cooperation_3 {
    left: 285px;
    top: 0;
}
.cooperation_4 {
    left: 425px;
    top: 290px;
}
.cooperation_5 {
    left: 570px;
    top: 0;
}
.cooperation_6 {
    left: 710px;
    top: 290px;
}
.cooperation_7 {
    left: 850px;
    top: 0;
}
.cooperation_8 {
    left: 990px;
    top: 290px;
}
.cooperation_9 {
    left: 1140px;
    top: 0;
}

.lunbo {
    width: 100%;
}
.lunbo-row {
    min-width: 1380px;
    margin: 0 auto;
}
.swiper-slide img {
    width: 100%;
    min-width: 1380px;
}
.weight {
    font-weight: 400;
}

/* 项目服务 */
#service-project {
    width: 100%;
    height: 644px;
}
.service-project-row {
    width: 1380px;
    margin: 0 auto;
    text-align: center;
}
.service-project-title {
    margin-top: 81px;
    text-align: center;
    font-size: 30px;
}
.service-project-desc {
    color: #888888;
    font-size: 18px;
    text-align: center;
}
.service-content {
    width: 1380px;
    margin: 0 auto;
    margin-top: 72px;
    display: flex;
    /* flex-wrap: wrap; */
}

.shu-xian {
    width: 1px;
    height: 386px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #333333 32%,
        #535353 72%,
        rgba(255, 255, 255, 0) 100%
    );
    border-radius: 0px 0px 0px 0px;
    opacity: 0.21;
}
.service-content-left {
    width: 689px;
    display: flex;
    justify-content: space-between;
}
.service-left-item {
    width: 344px;
}
.service-content-right {
    width: 690px;
    display: flex;
}
.letf-item-title {
    width: 276px;
    text-align: center;
    margin: 0 auto;
}
.letf-item-title-h3 {
    font-size: 18px;
    color: #333333;
}
.left-item-title-desc {
    color: #888888;
    font-size: 14px;
    margin-top: 10px;
    height: 60px;
}
.left-xian {
    width: 1px;
    height: 386px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #333333 32%,
        #535353 72%,
        rgba(255, 255, 255, 0) 100%
    );
    border-radius: 0px 0px 0px 0px;
    opacity: 0.21;
}
.project-content {
    width: 280px;
    display: flex;
    margin: 0 auto;
    margin-top: 59px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.project-item {
    width: 139px;
    height: 110px;
    text-align: center;
}
.project-item:hover {
    color: #0075ea;
    font-weight: 400;
}
.item-img {
    margin-top: 20px;
}
.item-shu-xian {
    border-right: 1px solid #dddddd;
}
.item-heng-xian {
    border-bottom: 1px solid #dddddd;
}

/* 为什么选择我们 */
.why {
    width: 100%;
    height: 626px;
    background-image: url("@/assets/images/why.png");
    background-repeat: no-repeat;
    background-size: 100% 626px;
    overflow: hidden;
}
.why-row {
    width: 1380px;
    margin: 0 auto;
    margin-top: 77px;
}
.why-title {
    color: #ffffff;
    font-size: 30px;
    margin-top: 77px;
    text-align: center;
}
.why-title-desc {
    color: #ffffff;
    font-size: 18px;
    margin-top: 13px;
    text-align: center;
}
.why-content {
    width: 1380px;
    margin: 0 auto;
    height: 322px;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    margin-top: 103px;
}
.why-content-item {
    width: 240px;
    text-align: center;
}
.why-content-item img {
    width: 240px;
}
.item-text {
    margin-top: 21px;
}

/* 经典案例 */
.anli {
    width: 100%;
    height: 1236px;
    overflow: hidden;
}
.anli-row {
    width: 1380px;
    margin: 0 auto;
    text-align: center;
}
.anli-title {
    color: #555555;
    font-size: 30px;
    text-align: center;
    margin-top: 73px;
    margin: 0 auto;
    margin-top: 73px;
}
.anli-content {
    width: 1380px;
    margin: 0 auto;
}
.anli-title-top {
    margin-top: 20px;
}
.anli-title-desc {
    color: #888888;
    font-size: 18px;
    text-align: center;
}
.anli-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 !important;
}
.anli-row-item {
    width: 448px;
    margin-top: 20px;
    cursor: pointer;
    pointer-events: auto;
}
.anli-row-one {
    margin-top: 86px;
}
.anli-row-img {
    width: 448px;
    height: 310px;
}
.anli-row-img img {
    width: 448px;
    height: 310px;
}
.anli-row-img img:hover {
    animation-name: scaleDraw;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 2s;
}
@keyframes scaleDraw {
    0% {
        transform: scale(1.01);
    }
    50% {
        transform: scale(1.02);
    }
}
.anli-row-text {
    /* padding-left: 24px; */
    padding: 28px 10px 0 10px;
    background-color: #f5f8fa;
    height: 56px;
    font-size: 20px;
}
.anli-row-text:hover {
    color: #0075ea;
}

.anli-row-two {
    margin-top: 20px;
}

/* 合作流程 */
.hz-lc {
    width: 100%;
    background-color: #f5f8fa;
    height: 847px;
    overflow: hidden;
}
.hz-lc-row {
    width: 1380px;
    margin: 37px auto 23px auto;
}
.hz-title {
    color: #555555;
    font-size: 30px;
    text-align: center;
    margin-top: 37px;
}
.item-text-content {
    width: 289px;
    height: 202px;
}
.hz-title-desc {
    color: #888888;
    font-size: 18px;
    margin-top: 23px;
    text-align: center;
}
.hz-content {
    margin-top: 116px;
    display: flex;
    justify-content: space-between;
}
.container {
    width: 1380px;
    margin: 116px auto 0 auto;
}
.container-content {
    width: 100%;
    height: 495px;
}
.container-content img {
    width: 100%;
    height: 100%;
}

/* 艾思动态 */
.dt {
    width: 100%;
    height: 748px;
    overflow: hidden;
}
.dt-row {
    width: 1380px;
    margin: 0 auto;
}
.dt-title {
    margin-top: 67px;
    text-align: center;
    color: #555555;
    font-size: 30px;
}
.dt-title-desc {
    margin-top: 29px;
    margin-bottom: 84px;
    text-align: center;
}
.dt-content {
    width: 1380px;
    height: 202px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.dt-content-item {
    margin-top: 20px;
    width: 606px;
    display: flex;
    justify-content: space-between;
}
.dt-content-item img {
    width: 297px;
    height: 202px;
}
.dt-content-item img:hover {
    opacity: 0.9;
}
.dt-item-right {
    width: 289px;
    text-align: left;
}
.item-right-title {
    color: #333333;
    font-size: 24px;
}
.item-right-title:hover {
    color: #0075ea;
}
.item-right-center {
    height: 80px;
    color: #888888;
    font-size: 14px;
    margin-top: 13px;
    margin-bottom: 22px;
    overflow: hidden;
}
.dt-item-right-bottom {
    font-size: 14px;
    color: #888888;
    display: flex;
    justify-content: space-between;
}
.dt-item-detail {
    cursor: pointer;
    pointer-events: auto;
}
.dt-item-detail:hover {
    color: #0075ea;
}

/* 联系我们 */
.lxwm {
    width: 100%;
    height: 498px;
    background-image: url("@/assets/images/lxwm-bg.png");
    background-repeat: no-repeat;
    overflow: hidden;
}
.lxwm-row {
    width: 1380px;
    margin: 0 auto;
}
.lxwm-title {
    text-align: center;
    color: #ffffff;
    font-size: 40px;
    margin-top: 147px;
}
.lxwm-text {
    font-size: 28px;
    text-align: center;
    color: #ffffff;
    margin-top: 27px;
    margin-bottom: 50px;
}
.lxwm-botton-content {
    width: 300px;
    margin: 0 auto;
}

.lxwm-button {
    display: block;
    margin: auto;
    width: 280px;
    height: 55px;
    line-height: 55px;
    background-color: #ffffff !important;
    border-radius: 3px 3px 3px 3px;
    text-align: center;
    font-size: 20px;
    font-weight: 0;
    color: #0075ea;
    cursor: pointer;

    background: linear-gradient(#1275d8 0 0) var(--p, 0) / var(--p, 0) no-repeat;
    transition: 0.4s 0s;
}
.lxwm-button:hover {
    --p: 100%;
    color: #fff;
}
</style>
