<template>
    <div>
        <div class="company"></div>
        <div class="crumbs">
            <div class="crumbs-content">
                主页 > 成功案例 >
                <span style="color: #0075ea">{{ product.title }}</span>
            </div>
        </div>

        <div class="news">
            <div class="news-conten">
                <div class="news-left">
                    <div class="left-lists">
                        <div class="left-lists-title">
                            {{ product.title }}
                        </div>
                        <div class="left-lists-time">
                            {{ product.create_time }}
                        </div>
                        <div
                            class="left-lists-content"
                            v-html="product.content"
                        ></div>
                    </div>
                </div>
                <div class="news-right">
                    <div class="news-right-title">
                        {{ product.title }}
                    </div>
                    <div class="new-right-center">
                        <div>类型：{{ product.type }}</div>
                        <div>行业：{{ product.industry }}</div>
                        <div>语言：{{ product.language }}</div>
                        <div>周期：{{ product.limit_time }}</div>
                        <div>色调：{{ product.ui_color_title }}</div>
                    </div>
                    <div class="news-right-bottom" v-html="product.intro"></div>

                    <div class="news-right-title">猜你喜欢</div>
                    <div class="xihuan-list">
                        <a
                            :href="'/productDetail?id=' + item.id"
                            v-for="(item, index) in lists"
                            :key="index"
                        >
                            <div class="xihuan-item">
                                <div class="xihuan-img">
                                    <img :src="item.img_url" alt="" />
                                </div>
                                <div class="xihuan-text">
                                    {{ item.title }}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getProductDetail, limitProduct } from '@/api/index/index'
import router from '@/router'
export default {
    data() {
        return {
            id: this.$route.query.id,
            product: {},
            lists: []
        }
    },
    created() {
        this.getProduct()
        this.getLimit()
    },
    methods: {
        getProduct() {
            getProductDetail({ id: this.id }).then(res => {
                this.product = res.data
            })
        },
        getLimit() {
            limitProduct({ limit: 3 }).then(res => {
                this.lists = res.data
            })
        },
        toDetail(id) {
            router.push({ path: '/productDetail', query: { id: id } })
        }
    }
}
</script>
<style scoped>
.company {
    width: 100%;
    height: 400px;
    background: url("@/assets/images/al-detail.png") center center;
    background-size: 100% auto;
}
.news {
    width: 100%;
}
.news-conten {
    width: 1380px;
    margin: 0 auto;
    display: flex;
}
.news-left {
    width: 935px;
    margin-bottom: 40px;
}
.news-title {
    width: 100%;
    height: 82px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
}
.crumbs {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    background-color: #f5f8fa;
    overflow: hidden;
}
.crumbs-content {
    width: 1380px;
    margin: 0 auto;
    text-align: left;
}
.left-lists {
    text-align: left;
    /* overflow: hidden; */
}
.left-lists-title {
    margin-top: 34px;
    font-size: 24px;
    font-weight: 500;
}

.left-lists-time {
    margin-top: 14px;
    margin-bottom: 28px;
    color: #888888;
    font-size: 14px;
}

.list-item-right {
    width: 100%;
    margin-left: 17px;
    text-align: left;
}
.list-item-right-top {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
}
.list-item-right-center {
    width: 650px;
    color: #888888;
    font-size: 16px;
    margin-top: 14px;
    margin-bottom: 14px;
}
.list-item-right-bottom {
    display: flex;
    justify-content: space-between;
}
.bottom-lll-num {
    position: relative;
}
.bottom-lll-img {
    width: 20px;
    height: 20px;
    position: absolute;
    left: -20px;
    top: 0;
}
.bottom-lll-img img {
    width: 20px;
}
.news-right {
    width: 385px;
    text-align: left;
}
.news-right-title {
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    font-weight: 500;
    color: #000000;
    border-bottom: 4px solid #0075ea;
    display: inline-block;
}

::v-deep .left-lists img {
    max-width: 90%;
}
.new-right-center {
    margin-top: 17px;
    margin-bottom: 40px;
}
.new-right-center,
.news-right-bottom {
    color: #555555;
    font-size: 16px;
    font-weight: 300;
}
.news-right-bottom {
    line-height: 26px;
    margin-bottom: 38px;
}
.xihuan-list {
    margin-top: 37px;
}
.xihuan-item {
    width: 384px;
    height: 340px;
    background: #f5f8fa;
    margin-bottom: 10px;
}
.xihuan-img {
    width: 384px;
    height: 270px;
}
.xihuan-img img {
    width: 100%;
}
.xihuan-text {
    color: #333333;
    font-size: 18px;
    line-height: 30px;
    margin-left: 21px;
}
.xihuan-text:hover {
    color: #0075ea;
}
</style>
