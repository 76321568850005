<template>
    <div>
        <div class="about-banner"></div>

        <div class="about">
            <div class="about-content">
                <div class="about-title">
                    <div class="about-title-item">
                        <a href="#about-detail">关于艾思</a>
                    </div>
                    <div class="about-title-item">
                        <a href="#fanwei">业务范围</a>
                    </div>
                    <div class="about-title-item">
                        <a href="#hz-lc">业务流程</a>
                    </div>
                    <div class="about-title-item">
                        <a href="#cooperative">合作企业</a>
                    </div>
                    <div class="about-title-item">
                        <a href="#cooperative-lxwm">联系我们</a>
                    </div>
                </div>

                <div id="about-detail" class="about-detail">
                    <div class="about-detail-left">
                        <img src="@/assets/images/about-left-img.png" alt="" />
                    </div>
                    <div class="about-detail-right">
                        <div>
                            山东艾思软件科技有限公司，是一家新型的以移动互联网开发为主的公司，成立于2018年，至于成立软件设计开发服务领域的顶尖公司。公司业务主要涉及网页开发，各类小程序开发，iOS（iPhone/iPad）、Android等APP定制开发。
                        </div>
                        <div>
                            艾思是一个具有丰富的软件开发，高素质的项目管理，专业扎实的技术、丰富策划经验的团队。
                            年轻、热情、积极进取、勇于创新、热爱思考是我们团队的整体风貌。诚信经营，按需定制
                            ，优质的个性化服务，想客户之所想,
                            是我们的服务理念。将项目负责到底，做好客户的技术团队是我们的承诺。紧贴网络时代的发展潮流，
                            不断创新， 策划出一个又一个的优秀APP产品，
                            获得用户的赞许是我们团队的一致追求。
                        </div>
                        <div>
                            秉承产品一贯的高品质，我们致力于为用户打造更优体验的各类软件。我们提供从APP策划、界面设计、交互设计、软件开发、专业服务一站式服务。
                        </div>
                        <div>我们是一群平凡的人，凝聚在一起做不平凡的事。</div>
                    </div>
                </div>

                <div class="about-youshi" id="fanwei">
                    <div
                        class="about-youshi-item"
                        @mouseenter="checkHtml(2, 0)"
                        @mouseleave="checkHtml(1, 0)"
                    >
                        <div v-show="html.htmlOne == 1">
                            <div>
                                <img src="@/assets/images/youshi1.png" alt="" />
                            </div>
                            <div>我们的优势</div>
                        </div>

                        <div
                            v-show="html.htmlOne == 2"
                            class="youshi-item-text"
                        >
                            我们具有丰富的互联网、移动互联网行业的从业经验，拥有高素质的核心管理层和专业扎实的技术、策划团队。
                            年轻、热情、积极进取、勇于创新是我们团队的整体风貌。优质的个性化服务,想客户之所想，提供合理化建议,精准数据分析,做客户的技术团队,将项目负责到底,引领互联网新时代,为企业拓展无限商机。
                        </div>
                    </div>
                    <div
                        class="about-youshi-item"
                        @mouseenter="checkHtml(2, 1)"
                        @mouseleave="checkHtml(1, 1)"
                    >
                        <div v-if="html.htmlTwo == 1">
                            <div>
                                <img src="@/assets/images/youshi3.png" alt="" />
                            </div>
                            <div>服务宗旨</div>
                        </div>
                        <div v-if="html.htmlTwo == 2" class="youshi-item-text">
                            我们会从产品开发者的角度，结合市场的需要做设计前的沟通，确保产品视觉设计质量的同时，优化交互和品牌定位，使得UI和产品形神合一。
                        </div>
                    </div>

                    <div
                        class="about-youshi-item"
                        @mouseenter="checkHtml(2, 2)"
                        @mouseleave="checkHtml(1, 2)"
                    >
                        <div v-if="html.htmlThree == 1">
                            <div>
                                <img src="@/assets/images/youshi2.png" alt="" />
                            </div>
                            <div>售后和技术帮助</div>
                        </div>
                        <div
                            v-if="html.htmlThree == 2"
                            class="youshi-item-text"
                        >
                            凡是我们的客户，在项目正式交付之日起提供一年的免费维护，7*24小时的响应速度，专业的技术修改建议，深度的合作与沉淀。
                            产品团队以用户体验为APP的生命, 精准定位客户需求,
                            紧贴网络时代的发展潮流, 不断创新, 策划出优秀产品,
                            “最好的用户体验, 追求卓越”是我们团队的一致追求。
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 合作流程 -->
        <div class="hz-lc" id="hz-lc">
            <div class="hz-lc-row">
                <div class="hz-title weight">业务流程</div>
            </div>
            <div class="row">
                <div class="hz-title-desc">
                    专家顾问企业私人量身打造，高效实战与高性价比的解决方案
                </div>
            </div>

            <div class="container">
                <div class="container-content">
                    <img id="liucheng" src="@/assets/images/hz1.png" alt="" />
                </div>
            </div>
        </div>

        <div class="cooperative" id="cooperative">
            <div class="cooperative-content">
                <div class="cooperative-title weight">合作企业</div>
                <div class="cooperative-desc">
                    客户不仅是我们的合作伙伴，更是一起并肩前进的朋友
                </div>

                <div class="cooperative-img-list">
                    <div
                        class="cooperative-img-item"
                        v-for="(item, index) in lists"
                        :key="index"
                    >
                        <img :src="item.img_url" alt="" />
                    </div>

                    <!-- <div class="cooperative-img-item">
                    <img src="@/assets/images/about10.png" alt="">
                </div> -->
                </div>

                <div class="cooperative-lxwm" id="cooperative-lxwm">
                    <div class="cooperative-lxwm-left">
                        <div class="lxwm-title">联系我们</div>
                        <div>
                            山东省临沂市河东区金华路国家级盘古众创空间产业园3楼
                        </div>
                        <div class="lxwm-desc">
                            <div>咨询热线：13365392580</div>
                            <div>微信联系：13365392580</div>
                            <div>邮箱地址：wangzhiliang@aisisoft.cn</div>
                        </div>
                    </div>
                    <div class="cooperative-lxwm-right">
                        <div class="lxwm-right-title">新的需求，从这里开始</div>
                        <div>
                            欢迎填写表格或发送合作邮箱至：<span
                                class="main-text"
                                >wangzhiliang@aisisoft.cn
                            </span>
                            微信：<span class="main-text">13365392580</span>
                        </div>
                        <div class="sub-form">
                            <div class="sub-form-left">
                                <div>
                                    <input
                                        v-model="saveData.name"
                                        placeholder="您的姓名"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <input
                                        v-model="saveData.company"
                                        placeholder="您的公司"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <input
                                        v-model="saveData.phone"
                                        placeholder="您的电话"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div class="sub-form-right">
                                <div>
                                    <textarea
                                        v-model="saveData.remark"
                                        placeholder="合作需求：详细产品描述、功能规划、数量、全歼平台等"
                                        class="textarea"
                                    ></textarea>
                                </div>
                                <div>
                                    <button
                                        @click="saveDataFun"
                                        class="sub-form-button"
                                    >
                                        联系我们
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getParent, saveMessage } from '@/api/index/index'
import { Message } from 'element-ui'
import $ from 'jquery'
export default {
    data() {
        return {
            timer: null,
            lists: [],
            saveData: {
                name: '',
                phone: '',
                company: '',
                remark: ''
            },
            html: {
                htmlOne: 1,
                htmlTwo: 1,
                htmlThree: 1
            }
        }
    },
    methods: {

        setTime() {
            let num = 1
            this.timer = setInterval(function () {
                let src = $('#liucheng')[0].src
                let sub = src.substr(-14, 1)
                if (sub == 9) {
                    num = 1;
                } else {
                    num++;
                }
                $('#liucheng').attr('src', require('@/assets/images/hz' + num + '.png'))
            }, 1000)
        },
        getParentData() {
            getParent().then(res => {
                this.lists = res.data
            })
        },
        saveDataFun() {
            saveMessage(this.saveData).then(res => {
                Message({
                    message: '添加成功，后续会有公司人员联系您',
                    type: 'success'
                });
                this.saveData = {
                    name: '',
                    phone: '',
                    company: '',
                    remark: ''
                }
            }).catch(err => {
                console.log(err)
            })
        },
        checkHtml(val, index) {
            if (index == 1) {
                this.html.htmlTwo = val
            } else if (index == 2) {
                this.html.htmlThree = val
            } else {
                this.html.htmlOne = val
            }
        }
    },
    mounted() {
        this.setTime()
        this.getParentData()
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>
<style scoped>
.about-banner {
    width: 100%;
    height: 400px;
    background-size: 100% auto;
    background: url("@/assets/images/about-banner.png") center center;
}
.about-banner img {
    min-width: 1380px;
    height: 550px;
}
.about {
    width: 100%;
    height: 1017px;
}
.about-content {
    width: 1380px;
    margin: 0 auto;
}
.about-title {
    height: 82px;
    line-height: 82px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
}
.about-title-item {
    margin-right: 128px;
    height: 78px;
}
.about-title-item a {
    color: #555555;
    font-size: 18px;
}
.about-title-item:hover {
    border-bottom: 6px solid #0075ea;
}
.about-title-item a:hover {
    color: #0075ea;
    font-weight: 400;
}
.about-detail-left {
    width: 646px;
}
.about-detail-right {
    width: 683px;
    color: #555555;
    font-size: 18px;
    text-align: left;
}
.about-detail {
    display: flex;
    justify-content: space-between;
    margin-top: 46px;
}
.about-detail div:nth-child(2) {
    margin-top: 40px;
    margin-bottom: 40px;
}
.about-detail div:nth-child(3) {
    margin-bottom: 16px;
}
.about-youshi {
    width: 1344px;
    height: 264px;
    margin: 39px auto 0 auto;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    display: flex;
}
.about-youshi-item {
    width: 448px;
    height: 220px;
    padding-top: 44px;
}

.about-youshi-item img {
    width: 106px;
    height: 106px;
}
.youshi-item-text {
    width: 360px;
    height: 173px;
    text-align: left;
    line-height: 28px;
    padding-left: 44px;
    padding-right: 44px;
}
.about-youshi-item:hover {
    background-color: #0075ea;
    color: #ffffff;
}

.hz-lc {
    width: 100%;
    background-color: #f5f8fa;
    height: 847px;
    overflow: hidden;
}
.hz-lc-row {
    width: 1380px;
    margin: 37px auto 23px auto;
}
.hz-title {
    color: #555555;
    font-size: 30px;
    text-align: center;
    margin-top: 37px;
}
.item-text-content {
    width: 289px;
    height: 202px;
}
.hz-title-desc {
    color: #888888;
    font-size: 18px;
    margin-top: 23px;
    text-align: center;
}
.hz-content {
    margin-top: 116px;
    display: flex;
    justify-content: space-between;
}
.container {
    width: 1380px;
    margin: 116px auto 0 auto;
}
.container-content {
    width: 100%;
    height: 495px;
}
.container-content img {
    width: 100%;
    height: 100%;
}

.weight {
    font-weight: 500;
}

.cooperative {
    width: 100%;
    height: 1150px;
}
.cooperative-content {
    width: 1380px;
    margin: 0 auto;
    overflow: hidden;
}
.cooperative-title {
    margin-top: 80px;
    margin-bottom: 23px;
    font-size: 30px;
}
.cooperative-desc {
    color: #888888;
    font-size: 18px;
    margin-bottom: 65px;
}
.cooperative-img-list {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}
.cooperative-img-item {
    width: 257px;
    height: 121px;
    margin-bottom: 25px;
    margin-right: 10px;
}
.cooperative-img-item img {
    max-width: 247px;
    max-height: 111px;
}

.cooperative-lxwm {
    display: flex;
    margin-top: 30px;
}
.cooperative-lxwm-left {
    text-align: left;
    margin-left: 20px;
}
.lxwm-title {
    font-size: 30px;
    font-weight: 500;
}
.lxwm-desc div {
    margin-top: 14px;
}

.cooperative-lxwm-right {
    text-align: left;
    margin-left: 164px;
}

.lxwm-right-title {
    font-weight: 500;
    color: #555555;
}
.main-text {
    color: #0075ea;
}
.sub-form {
    display: flex;
}
.sub-form-left {
    margin-right: 19px;
}
.sub-form-left div {
    margin-top: 10px;
}
.sub-form-left input {
    width: 367px;
    height: 20px;
    border-radius: 2px 2px 2px 2px;
    padding: 11px 11px 9px 11px;
    border: 1px solid #cccfd1;
}
.textarea {
    width: 367px;
    height: 72px;
    padding: 11px;
    margin-top: 10px;
    resize: none;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #cccfd1;
}
.sub-form-button {
    width: 389px;
    height: 40px;
    color: #ffffff;
    line-height: 40px;
    background: #0075ea;
    border-radius: 4px 4px 4px 4px;
    border: 0;
}
input::input-placeholder,
.textarea::input-placeholder {
    color: #c2c9ce;
}
</style>
