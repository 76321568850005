<template>
    <div>
        <div class="company"></div>
        <div class="crumbs">
            <div class="crumbs-content">
                主页 > 知识/咨询 >
                <span style="color: #0075ea">{{ news.title }}</span>
            </div>
        </div>

        <div class="news">
            <div class="news-conten">
                <div class="news-left">
                    <div class="left-lists">
                        <div class="left-lists-title">
                            {{ news.title }}
                        </div>
                        <div
                            style="
                                display: flex;
                                background: #f4f4f4;
                                align-items: center;
                                margin-top: 10px;
                            "
                        >
                            <div class="left-lists-time">
                                发布日期:
                                {{ news.create_time.substring(0, 10) }}
                            </div>
                            <div class="bottom-tags" v-if="news.tags[0].length">
                                <span
                                    v-for="(value, key) in news.tags"
                                    :key="key"
                                    >{{ value }}</span
                                >
                            </div>
                        </div>
                        <div
                            class="left-lists-content"
                            v-html="news.content"
                        ></div>
                    </div>
                </div>
                <div class="news-right">
                    <div>
                        <div class="news-right-title">动态推荐</div>
                        <div class="news-right-lists">
                            <div
                                v-for="(item, index) in limitNew"
                                :key="index"
                                class="news-right-item"
                            >
                                <a :href="'/detail?id=' + item.id"
                                    >{{ index + 1 }}、{{ item.title }}</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getNewDetail, limitData } from '@/api/index/index'
import router from '@/router'
export default {
    data() {
        return {
            id: this.$route.query.id,
            news: {},
            limitNew: []
        }
    },
    created() {
        this.getNew()
        this.getLimitNew()
    },
    methods: {
        getNew() {
            getNewDetail({ id: this.id }).then(res => {
                this.news = res.data
                // console.log(this.news['tags']);
                this.news['tags'] = this.news['tags'].split(',');
            })
        },
        getLimitNew() {
            limitData({ limit: 10 }).then(res => {
                this.limitNew = res.data
            })
        },
        toDetail(id) {
            router.push({ path: '/detail', query: { id: id } })
        }
    }
}
</script>
<style scoped>
.company {
    width: 100%;
    height: 400px;
    background: url("@/assets/images/company-banner.png") center center;
    background-size: 100% auto;
}
.news {
    width: 100%;
    margin-bottom: 40px;
}
.news-conten {
    width: 1380px;
    min-height: 500px;
    margin: 0 auto;
    display: flex;
}
.news-left {
    width: 935px;
}
.news-title {
    width: 100%;
    height: 82px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
}
.crumbs {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    background-color: #f5f8fa;
    overflow: hidden;
}
.crumbs-content {
    width: 1380px;
    margin: 0 auto;
    text-align: left;
}
.left-lists {
    text-align: left;
}
.left-lists-title {
    margin-top: 34px;
    font-size: 24px;
    font-weight: 500;
}

.left-lists-time {
    width: 20%;
    color: #888888;
    font-size: 14px;
    margin: 20px;
}

.list-item-right {
    width: 100%;
    margin-left: 17px;
    text-align: left;
}
.list-item-right-top {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
}
.list-item-right-center {
    width: 650px;
    color: #888888;
    font-size: 16px;
    margin-top: 14px;
    margin-bottom: 14px;
}
.list-item-right-bottom {
    display: flex;
    justify-content: space-between;
}
.bottom-tags {
    text-align: right;
    width: 80%;
    margin-right: 10px;
}

.bottom-tags span {
    border: 1px solid #e9e9e9;
    padding: 2px 8px;
    margin: 0px 5px;
    border-radius: 10px;
    background: #f8f8f8;
    color: #888888;
    font-size: 14px;
}
.bottom-lll-img img {
    width: 20px;
}
.news-right-title {
    font-size: 18px;
    height: 78px;
    font-weight: 500;
    color: #000000;
    margin-left: 68px;
    line-height: 82px;
    border-bottom: 4px solid #0075ea;
}
.news-right-item {
    color: #555555;
}
.news-right-item a {
    color: #555555;
}
.news-right-item a:hover {
    color: #0075ea;
}
.news-right-lists {
    text-align: left;
    margin-left: 68px;
    margin-top: 17px;
    line-height: 26px;
}
.left-lists-content {
    overflow: hidden;
}
::v-deep .left-lists-content table {
    border-collapse: separate;
    border-spacing: 0;
    border-top: 1px solid #d9d9d9 !important;
    border-left: 1px solid #d9d9d9 !important;
}
::v-deep .left-lists-content table td {
    padding: 5px;
    border-right: 1px solid #d9d9d9 !important;
    border-bottom: 1px solid #d9d9d9 !important;
}
::v-deep .left-lists-content img {
    width: 100%;
}
</style>
