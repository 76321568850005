<template>
  <div id="app">
    <header-view></header-view>
    <tips-view></tips-view>
    <router-view />
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";
import TipsView from "@/components/TipsView.vue";
export default {
  components: {
    HeaderView,
    FooterView,
    TipsView,
  },
  methods: {
    isMobileDevice() {
      //判断当前设备是否为移动端
      const ua = navigator.userAgent.toLowerCase();
      const t1 =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          ua
        );
      const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1;
      return t1 || t2;
    },
  },
  created() {
    let isPhone = this.isMobileDevice();

    if(isPhone) {
      location.href="http://app.aisisoft.com.cn/"
    }
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
  padding: 0;
  color: #333333;
  font-size: 16px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
</style>
