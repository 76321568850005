import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'
import CustomerView from '../views/customer/index.vue'
import AppletView from '../views/service/applet.vue'
import WechatView from '../views/service/wechat.vue'
import AppView from '../views/service/app.vue'
import WebView from '../views/service/web.vue'
import ShopView from '../views/solution/shop.vue'
import OaView from '../views/solution/oa.vue'
import MsnView from '../views/solution/msn.vue'
import MedicalView from '../views/solution/medical.vue'
import LogisView from '../views/solution/logis.vue'
import LiveView from '../views/solution/live.vue'
import HardView from '../views/solution/hard.vue'
import EduView from '../views/solution/edu.vue'
import CompanyView from '../views/company/index.vue'
import DetailView from '../views/company/detail.vue'
import AboutView from '../views/about/index.vue'
import ProductView from '../views/customer/detail.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/customer',
    name: 'customer',
    component: CustomerView
  },
  {
    path: '/applet',
    name: 'applet',
    component: AppletView
  },
  {
    path: '/wechat',
    name: 'wechat',
    component: WechatView
  },
  {
    path: '/app',
    name: 'app',
    component: AppView
  },
  {
    path: '/web',
    name: 'web',
    component: WebView
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopView
  },
  {
    path: '/oa',
    name: 'oa',
    component: OaView
  },
  {
    path: '/msn',
    name: 'msn',
    component: MsnView
  },
  {
    path: '/medical',
    name: 'medical',
    component: MedicalView
  },
  {
    path: '/logis',
    name: 'logis',
    component: LogisView
  },
  {
    path: '/live',
    name: 'live',
    component: LiveView
  },
  {
    path: '/hard',
    name: 'hard',
    component: HardView
  },
  {
    path: '/edu',
    name: 'edu',
    component: EduView
  },
  {
    path: '/company',
    name: 'company',
    component: CompanyView
  },
  {
    path: '/detail',
    name: 'detail',
    component: DetailView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: ProductView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
