<template>
    <div>
        <div class="header">
            <div class="nav-header">
                <div class="nav-item">
                    <img
                        class="left-logo"
                        src="@/assets/images/logo.png"
                        alt=""
                    />
                </div>
                <div class="right-header">
                    <div
                        @mousemove="
                            hideFuWu();
                            hideJieJue();
                        "
                        class="nav-item"
                    >
                        <a href="/">首页</a>
                    </div>
                    <div
                        @mousemove="
                            hideFuWu();
                            hideJieJue();
                        "
                        class="nav-item"
                    >
                        <a href="/customer">客户案例</a>
                    </div>
                    <div
                        @mousemove="
                            hideJieJue();
                            showFuWu();
                        "
                        class="nav-item fuwu"
                    >
                        <a href="#">服务范围</a>
                        <div class="sanjiao">
                            <img
                                id="sanjiao"
                                style="width: 10px"
                                src="@/assets/images/san-xia.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div
                        @mousemove="
                            hideFuWu();
                            showJieJue();
                        "
                        class="nav-item jiejue"
                    >
                        <a href="#">解决方案</a>
                        <div class="sanjiao">
                            <img
                                id="jiejue-sj"
                                style="width: 10px"
                                src="@/assets/images/san-xia.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div
                        @mousemove="
                            hideFuWu();
                            hideJieJue();
                        "
                        class="nav-item"
                    >
                        <a href="/company">知识/资讯</a>
                    </div>
                    <div
                        @mousemove="
                            hideFuWu();
                            hideJieJue();
                        "
                        class="nav-item"
                    >
                        <a href="/about">关于我们</a>
                    </div>
                </div>
            </div>

            <div class="fuwu-xiala" @mouseleave="hideFuWu()" id="fuwu-xiala">
                <div class="fuwu-xiala-content">
                    <div @click="toPage('/wechat')" class="fuwu-xiala-item">
                        <div class="fuwu-item-img">
                            <img src="@/assets/images/wx.png" alt="" />
                        </div>
                        <div>
                            <div class="fuwu-item-title">微信开发</div>
                            <div class="fuwu-item-text">
                                微信开发抓住亿万级移动流量红利
                            </div>
                        </div>
                    </div>

                    <div @click="toPage('/applet')" class="fuwu-xiala-item">
                        <div class="fuwu-item-img">
                            <img src="@/assets/images/xcx.png" alt="" />
                        </div>
                        <div>
                            <div class="fuwu-item-title">小程序开发</div>
                            <div class="fuwu-item-text">
                                致力于企业级微信小程序开发服务
                            </div>
                        </div>
                    </div>

                    <div @click="toPage('/app')" class="fuwu-xiala-item">
                        <div @mouseout="test($event)" class="fuwu-item-img">
                            <img src="@/assets/images/app.png" alt="" />
                        </div>
                        <div>
                            <div
                                @mouseout="test($event)"
                                class="fuwu-item-title"
                            >
                                app开发
                            </div>
                            <div
                                @mouseout="test($event)"
                                class="fuwu-item-text"
                            >
                                提供专业极致的app开发服务
                            </div>
                        </div>
                    </div>

                    <div @click="toPage('/web')" class="fuwu-xiala-item">
                        <div class="fuwu-item-img">
                            <img src="@/assets/images/web.png" alt="" />
                        </div>
                        <div>
                            <div class="fuwu-item-title">网站开发</div>
                            <div class="fuwu-item-text">
                                按需定制、源码交付、运营无忧
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 解决方案 -->
            <div
                @mouseleave="hideJieJue()"
                class="fuwu-xiala jiejue-xiala"
                id="jiejue-xiala"
            >
                <div>
                    <div class="jiejue-sum-item">
                        <div
                            @click="toPage('/msn')"
                            class="fuwu-xiala-item jiejue-item"
                        >
                            <div class="fuwu-item-img">
                                <img src="@/assets/images/jj1.png" alt="" />
                            </div>
                            <div>
                                <div class="fuwu-item-title">
                                    即时通讯解决方案
                                </div>
                                <div class="fuwu-item-text">
                                    提供越来越丰富的通讯服务功能
                                </div>
                            </div>
                        </div>
                        <div
                            @click="toPage('/edu')"
                            class="fuwu-xiala-item jiejue-item"
                        >
                            <div class="fuwu-item-img">
                                <img src="@/assets/images/jj2.png" alt="" />
                            </div>
                            <div>
                                <div class="fuwu-item-title">教育解决方案</div>
                                <div class="fuwu-item-text">
                                    为学员和机构提供专业自主的网络服务
                                </div>
                            </div>
                        </div>

                        <div
                            @click="toPage('/medical')"
                            class="fuwu-xiala-item jiejue-item"
                        >
                            <div class="fuwu-item-img">
                                <img src="@/assets/images/jj3.png" alt="" />
                            </div>
                            <div>
                                <div class="fuwu-item-title">
                                    智能医疗解决方案
                                </div>
                                <div class="fuwu-item-text">
                                    发展中国医疗卫生服务提供有效方法
                                </div>
                            </div>
                        </div>

                        <div
                            @click="toPage('/live')"
                            class="fuwu-xiala-item jiejue-item"
                        >
                            <div class="fuwu-item-img">
                                <img src="@/assets/images/jj4.png" alt="" />
                            </div>
                            <div>
                                <div class="fuwu-item-title">
                                    视频直播解决方案
                                </div>
                                <div class="fuwu-item-text">
                                    只需拿出手机，全平台用户都能观看
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="jiejue-sum-item">
                        <div
                            @click="toPage('/logis')"
                            class="fuwu-xiala-item jiejue-item"
                        >
                            <div class="fuwu-item-img">
                                <img src="@/assets/images/jj5.png" alt="" />
                            </div>
                            <div>
                                <div class="fuwu-item-title">物流解决方案</div>
                                <div class="fuwu-item-text">
                                    帮助物流提升服务质量和管理效率
                                </div>
                            </div>
                        </div>

                        <div
                            @click="toPage('/oa')"
                            class="fuwu-xiala-item jiejue-item"
                        >
                            <div class="fuwu-item-img">
                                <img src="@/assets/images/jj6.png" alt="" />
                            </div>
                            <div>
                                <div class="fuwu-item-title">办公解决方案</div>
                                <div class="fuwu-item-text">
                                    为企业实现高效协同移动办公服务
                                </div>
                            </div>
                        </div>

                        <div
                            @click="toPage('/shop')"
                            class="fuwu-xiala-item jiejue-item"
                        >
                            <div class="fuwu-item-img">
                                <img src="@/assets/images/jj7.png" alt="" />
                            </div>
                            <div>
                                <div class="fuwu-item-title">电商解决方案</div>
                                <div class="fuwu-item-text">
                                    实现网上购物和商户之间各种商务活动
                                </div>
                            </div>
                        </div>

                        <div
                            @click="toPage('/hard')"
                            class="fuwu-xiala-item jiejue-item"
                        >
                            <div class="fuwu-item-img">
                                <img src="@/assets/images/jj8.png" alt="" />
                            </div>
                            <div>
                                <div class="fuwu-item-title">
                                    智能硬件解决方案
                                </div>
                                <div class="fuwu-item-text">
                                    加强用户粘性，增强企业的市场竞争力
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import router from '@/router'
export default {
    data() {
        return {

        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    },
    methods: {
        // 获取页面滚动距离
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if (scrollTop >= 70) {
                this.hideFuWu()
                this.hideJieJue()
            }
        },
        showFuWu() {
            $($('#sanjiao')[0]).attr('src', require('@/assets/images/sanjiao-shang.png'))
            $('#fuwu-xiala').slideDown()
        },
        hideFuWu() {
            $($('#sanjiao')[0]).attr('src', require('@/assets/images/san-xia.png'))
            $('#fuwu-xiala').slideUp()
        },
        toPage(url) {
            this.hideFuWu()
            this.hideJieJue()
            router.push(url)
        },
        showJieJue() {
            $($('#sanjiao')[0]).attr('src', require('@/assets/images/sanjiao-shang.png'))
            $('#jiejue-xiala').slideDown()
        },
        hideJieJue() {
            $($('#sanjiao')[0]).attr('src', require('@/assets/images/san-xia.png'))
            $('#jiejue-xiala').slideUp()
        },
        test(e) {
            e.stopPropagation()
        }
    }
}
</script>
   
<style  scoped>
.nav-header {
    width: 1380px;
    height: 70px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.right-header {
    width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}
.right-header a {
    color: #333333;
}
.left-logo {
    width: 146px;
    height: 32px;
    margin-top: 15px;
}

.fuwu-xiala {
    width: 100%;
    height: 88px;
    z-index: 10;
    display: none;
    position: absolute;
    top: 70px;
    background-color: #ffffff;
}
.jiejue-xiala {
    height: 208px;
}

.fuwu-xiala-content {
    width: 1380px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
}
.jiejue-sum-item {
    width: 1380px;
    height: 100%;
    margin: 0 auto;
    display: flex;
}
.fuwu-xiala-item {
    width: 400px;
    display: flex;
    margin-top: 15px;
    margin-bottom: 21px;
    margin-left: 55px;
    cursor: pointer;
    text-align: left;
}
.jiejue-item {
    min-width: 246px;
}
.fuwu-item-img {
    width: 42px;
    height: 42px;
    margin-right: 5px;
}
.fuwu-item-img img {
    width: 100%;
}
.fuwu-item-title {
    font-size: 16px;
    color: #333333;
}
.fuwu-item-title:hover {
    color: #0075ea;
}
.fuwu-item-text {
    color: #555555;
    font-size: 14px;
    margin-top: 10px;
}
.nav-item {
    width: 100px;
    height: 70px;
    line-height: 70px;
    position: relative;
}
.nav-item :hover {
    color: #0075ea;
}

.sanjiao {
    position: absolute;
    display: inline-block;
    left: 84px;
}
/* .fuwu{
    height: 70px;
    line-height: 70px;
} */
.fuwu:hover {
    color: #333333;
    font-weight: 400;
}
</style>