<template>
    <div>
        <div class="tips">
            <div class="tips-item" onclick="_MEIQIA('showPanel')">
                <div>
                    <img src="@/assets/images/tips1.png" alt="" />
                </div>
                <div>在线咨询</div>
            </div>
            <a href="#lx" style="color: #ffffff">
                <div class="tips-item">
                    <div>
                        <img src="@/assets/images/tips2.png" alt="" />
                    </div>
                    <div>微信咨询</div>
                </div>
            </a>

            <div
                @mouseenter="showPhone()"
                @mouseleave="hidePhone()"
                class="tips-item tips-parent"
            >
                <div class="tips-parent-item">
                    <img
                        class="tips-img-phone"
                        src="@/assets/images/tips3.png"
                        alt=""
                    />
                </div>
                <div class="tips-parent-item">服务热线</div>
                <div class="tips-phone tips-parent-item">13365392580</div>
            </div>
            <div class="tips-item" @click="scrollTop()">
                <div>
                    <img src="@/assets/images/tips4.png" alt="" />
                </div>
                <div>TOP</div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    data() {
        return {

        }
    },
    methods: {
        scrollTop() {
            $('html,body').animate({ scrollTop: '0px' }, 800);
        },
        showPhone() {
            // console.log(7777)
            $('.tips-phone').fadeIn()
        },
        hidePhone() {
            // console.log(8888)
            $('.tips-phone').fadeOut()
        },
        initTips() {
            if ($(window).scrollTop() >= 100) {
                $('.tips').fadeIn(300);
            } else {
                $('.tips').fadeOut(300);
            }
        }
    },
    mounted() {
        this.initTips()
        window.addEventListener('scroll', this.initTips) // 监听页面滚动

    }
}
</script>
<style scoped>
.tips {
    width: 60px;
    height: 280px;

    position: fixed;
    right: 0;
    top: 46%;
    z-index: 100;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
}
.tips-item {
    height: 60px;
    padding-top: 9px;
    background-color: rgba(51, 51, 51, 0.5);
}
.tips-item img {
    width: 40px;
    height: 36px;
}
.tips-item:hover {
    background: #0075ea;
}
.tips-parent {
    position: relative;
}
.tips-parent:hover {
    background-color: #0075ea;
}
.tips-phone {
    position: absolute;
    height: 69px;
    line-height: 69px;
    width: 116px;
    padding-left: 10px;
    background-color: #0075ea;
    z-index: 200;
    right: 60px;
    top: 0px;
    font-size: 16px;
    display: none;
}

.tips-item a {
    color: #ffffff;
}
</style>