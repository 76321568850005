<template>
    <div>
        <div class="customer-banner"></div>

        <div class="customer-anli">
            <div class="customer-anli-content">
                <div class="customer-type">
                    <div @click="byCategoryGetPartnerData(0)" class="type-item">
                        所有行业
                    </div>
                    <div
                        @click="byCategoryGetPartnerData(item.id)"
                        v-for="(item, index) in categories"
                        :key="index"
                        class="type-item"
                    >
                        {{ item.title }}
                    </div>
                </div>
                <!-- 经典案例 -->
                <div class="anli-content">
                    <div class="col-md-12 anli-row anli-row-one">
                        <div
                            class="col-md-4 anli-row-item"
                            @click="toDetail(item.id)"
                            v-for="(item, index) in productsList"
                            :key="index"
                        >
                            <div class="anli-row-img">
                                <img
                                    class="img-responsive"
                                    :src="item.img_url"
                                    alt=""
                                />
                            </div>
                            <div class="anli-row-text">
                                <div>
                                    {{ item.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCategories, byCategoryGetPartner } from '@/api/index/index'
import router from '@/router';
export default {
    data() {
        return {
            productsList: [],
            categories: []
        }
    },
    created() {
        this.getData();
        this.byCategoryGetPartnerData();
    },
    methods: {
        getData() {
            getCategories().then(res => {
                this.categories = res.data
            })
        },
        byCategoryGetPartnerData(id) {
            if (id == undefined) {
                id = 0
            }
            byCategoryGetPartner({ id: id }).then(res => {
                this.productsList = res.data
            })
        },
        toDetail(id) {
            console.log(8888)
            router.push({
                path: '/productDetail', query: {
                    id: id
                }
            })
        }
    }
}
</script>
<style scoped>
.customer-banner {
    width: 100%;
    height: 400px;
    background: url("@/assets/images/customer-banner.png") center center;
    background-size: 100% auto;
}

.customer-banner-content {
    width: 1380px;
    height: 100%;
    text-align: center;
    color: #ffffff;

    margin: 0 auto;
}
.customer-title {
    font-size: 45px;
}
.customer-text {
    font-size: 24px;
}

.customer-anli {
    width: 100%;
    overflow: hidden;
}
.customer-anli-content {
    width: 1380px;
    margin: 0 auto;
    min-height: 1000px;
    padding-bottom: 40px;
    overflow: hidden;
}
.customer-type {
    width: 700px;
    display: flex;
    color: #333333;
    font-size: 14px;
    justify-content: space-between;
    margin-top: 41px;
    margin-bottom: 0px;
}
.type-item {
    width: 110px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 1px solid #e0e0e0;
    cursor: pointer;
}
.type-item:hover {
    background-color: #0075ea;
    color: #ffffff;
    border: 1px solid #0075ea;
}
.customer-type a {
    color: #333333;
}

.anli {
    width: 100%;
    height: 1236px;
    overflow: hidden;
}
.anli-row {
    width: 1380px;
    margin: 0 auto;
    text-align: center;
}
.anli-title {
    color: #555555;
    font-size: 30px;
    text-align: center;
    margin-top: 73px;
    margin: 0 auto;
    margin-top: 73px;
}
.anli-content {
    width: 1380px;
    margin: 0 auto;
}
.anli-title-top {
    margin-top: 20px;
}
.anli-title-desc {
    color: #888888;
    font-size: 18px;
    text-align: center;
}
.anli-row {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    padding: 0 !important;
}
.anli-row-item {
    width: 448px;
    margin-top: 20px;
    margin-right: 10px;
}
.anli-row-one {
    margin-top: 20px;
}
.anli-row-img {
    width: 448px;
    height: 310px;
}
.anli-row-img img {
    width: 448px;
    height: 310px;
}
.anli-row-text {
    /* padding-left: 24px; */
    padding-top: 28px;

    background-color: #f5f8fa;
    height: 64px;
    overflow: hidden;
}
/* 0075EA */
.anli-row-text-hover {
    height: 9px;
    background-color: #f5f8fa;
}
.anli-row-text div:nth-child(1) {
    color: #333333;
    font-size: 24px;
}
.anli-row-text div:nth-child(2) {
    color: #888888;
    font-size: 16px;
    margin-top: 12px;
    padding-bottom: 17px;
}
.anli-row-two {
    margin-top: 20px;
}
</style>
