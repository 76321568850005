import request from '@/utils/request'

export function getHomeData(data) {
    return request({
      url: '/index/Index/index',
      method: 'post',
      data
    })
}

export function getCategories(data) {
  return request({
    url: '/index/Index/getCategory',
    method: 'post',
    data
  })
}

export function byCategoryGetPartner(data) {
  return request({
    url: '/index/Index/byCategoryGetPartner',
    method: 'post',
    data
  })
}

export function getNewDetail(data) {
  return request({
    url: '/index/Index/getNewDetail',
    method: 'post',
    data
  })
}

export function getProductDetail(data) {
  return request({
    url: '/index/Index/getProductDetail',
    method: 'post',
    data
  })
}

export function getParent(data) {
  return request({
    url: '/index/Index/getParent',
    method: 'post',
    data
  })
}


export function getAllNews(data) {
  return request({
    url: '/index/Index/getAllNews',
    method: 'post',
    data
  })
}

export function saveMessage(data) {
  return request({
    url: '/index/Index/saveMessage',
    method: 'post',
    data
  })
}

export function limitData(data) {
  return request({
    url: '/index/Index/limitData',
    method: 'post',
    data
  })
}

export function limitProduct(data) {
  return request({
    url: '/index/Index/limitProduct',
    method: 'post',
    data
  })
}





