<template>
    <div>
        <!-- 服务范围 web-->
        <div class="services-banner"></div>

        <div class="hao-web">
            <div class="content">
                <div class="hao-web-top">
                    <div class="web-top-left">
                        <div class="web-top-left-title weight">
                            怎样才是好网站
                        </div>
                        <div class="web-top-left-desc">
                            让您的网站走在互联网的前端！以最佳的用户体验，让客户随时随地找到您！所谓响应式网站，是指可以自动识别屏幕宽度、并做出相应调整的网页设计。简单来说就是同一张网页自动适应不同大小的屏幕，根据屏幕宽度，自动调整布局。现在使用移动设备人越来越多，移动版的Website随之也越来越重要；但是移动端设备的大小不一，屏幕分辨率各不相同，我们不可能为BlackBerry，iPhone，iPad等等每个都做单独的页面设计。
                        </div>
                    </div>
                    <div>
                        <img src="@/assets/images/hao-web-right.png" alt="" />
                    </div>
                    <div></div>
                </div>
                <div class="web-bottom-total">
                    <div class="web-bottom-item">
                        <div class="web-bottom-item-title weight">
                            更省钱、更赚钱
                        </div>
                        <div class="web-bottom-item-desc">
                            核心关键筛选定位，让流量更精准
                        </div>
                    </div>
                    <div class="web-bottom-item">
                        <div class="web-bottom-item-title weight">
                            吸引用户眼球
                        </div>
                        <div class="web-bottom-item-desc">吸引用户眼球</div>
                        <div class="web-bottom-item-desc">
                            好看，在今天这个讲究高颜值的时代显得尤为突出
                        </div>
                    </div>
                    <div class="web-bottom-item">
                        <div class="web-bottom-item-title weight">
                            有转化率的网站
                        </div>
                        <div class="web-bottom-item-desc">
                            突出核心内容，聚焦访客眼球
                        </div>
                    </div>
                    <div class="web-bottom-item">
                        <div class="web-bottom-item-title weight">
                            搜索引擎更加喜欢
                        </div>
                        <div class="web-bottom-item-desc">
                            好的SEO网站更加容易搜索排名第一
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="web-seo">
            <div class="content web-seo-content">
                <div class="web-seo-left">
                    <img src="@/assets/images/seo-img.png" alt="" />
                </div>
                <div class="web-seo-right">
                    <div class="web-seo-top">
                        <div class="seo-right-title weight">
                            撼动高端定制更符合搜索引擎抓取SEO优化原则
                        </div>
                        <div class="seo-right-desc">
                            CONFORM TO THE SEARCH ENGINES CATCH SEO OPTIMIZATION
                            RULES
                        </div>
                    </div>
                    <div class="web-seo-bottom">
                        <div>
                            让您的网站，赢在起跑线上，节省维护成本，搜索推广事半功倍！
                        </div>
                        <div>
                            Create outstanding websites for you. Save your
                            maintenance cost.
                        </div>
                        <div>
                            Make search campaign yielding twice the result with
                            half the effort.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="web-ysp">
            <div class="content">
                <div class="web-ysp-content-text">
                    <div class="web-ysp-content-top">
                        <div class="web-ysp-content-title title-size">
                            无可挑剔的撼动高端定制网站策划 媲美精湛艺术品
                        </div>
                        <div class="web-ysp-content-desc desc-size">
                            通过精美建站结合6大标准流程，全方位解决企业的营销、定位、品牌、传播等问题。实现全网营销、大幅提升企业品牌形象，降低营销成本。
                        </div>
                    </div>
                </div>

                <div class="web-ysp-content-total">
                    <div>
                        <img src="@/assets/images/ysp1.png" alt="" />
                    </div>
                    <div>
                        <img src="@/assets/images/ysp2.png" alt="" />
                    </div>
                    <div>
                        <img src="@/assets/images/ysp3.png" alt="" />
                    </div>
                    <div>
                        <img src="@/assets/images/ysp4.png" alt="" />
                    </div>
                    <div>
                        <img src="@/assets/images/ysp5.png" alt="" />
                    </div>
                    <div>
                        <img src="@/assets/images/ysp6.png" alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div class="web-fwly">
            <div class="content">
                <div class="web-fwly-text">
                    <div class="web-fwly-title title-size">
                        服务领域/OUR SERVICE
                    </div>
                    <div class="web-fwly-text-desc desc-size">
                        我们基于用户的需求主导产品研发，提供丰富、安全、易用、灵活的云产品
                    </div>
                </div>

                <div class="web-fwly-total">
                    <div class="web-fwly-item1">
                        <div>
                            <img src="@/assets/images/fwly1.png" alt="" />
                        </div>
                        <div class="fwly-title">品牌型网站</div>
                        <div class="fwly-desc">塑造品牌形象，打造品牌价值</div>
                    </div>
                    <div class="web-fwly-item2">
                        <div class="item2-top">
                            <div class="item2-top-text">
                                <div class="fwly-title">互动营销</div>
                                <div class="fwly-desc">高效互动、提升业绩</div>
                            </div>
                            <div>
                                <img src="@/assets/images/fwly2.png" alt="" />
                            </div>
                        </div>
                        <div class="item2-bottom">
                            <div>
                                <img src="@/assets/images/fwly3.png" alt="" />
                            </div>
                            <div class="fwly-title">网上商城</div>
                            <div class="fwly-desc">
                                聪明友善网站，网罗生意大管家
                            </div>
                        </div>
                    </div>
                    <div class="web-fwly-item3">
                        <div class="item3-top">
                            <div>
                                <img src="@/assets/images/fwly4.png" alt="" />
                            </div>
                            <div class="fwly-title">内外网信息网</div>
                            <div class="fwly-desc">信息互通，同意管理</div>
                        </div>
                        <div class="item3-bottom">
                            <div>
                                <img src="@/assets/images/fwly5.png" alt="" />
                            </div>
                            <div class="item3-bottom-text">
                                <div class="fwly-title">行业门户</div>
                                <div class="fwly-desc">
                                    打造商务交互平台，树立行业权威形象
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hz-lc">
            <div class="hz-lc-row">
                <div class="hz-title weight">9重保障 让您开发全程无忧</div>
            </div>
            <div class="row">
                <div class="hz-title-desc">
                    专家顾问企业私人量身打造，高效实战与高性价比的解决方案
                </div>
            </div>

            <div class="container-liuc">
                <div class="container-content">
                    <img id="liucheng" src="@/assets/images/hz1.png" alt="" />
                </div>
            </div>
        </div>
        <div class="web-liyou">
            <div class="content">
                <div class="liyou-title title-size">选择我们的理由</div>
                <div class="liyou-img">
                    <img src="@/assets/images/web-liyou.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            timer: null
        }
    },
    methods: {
        setTime() {
            let num = 1
            this.timer = setInterval(function () {
                let src = $('#liucheng')[0].src
                let sub = src.substr(-14, 1)
                if (sub == 9) {
                    num = 1;
                } else {
                    num++;
                }
                $('#liucheng').attr('src', require('@/assets/images/hz' + num + '.png'))
            }, 1000)
        },
    },
    mounted() {
        this.setTime()
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>
<style scoped>
.services-banner {
    width: 100%;
    height: 550px;
    background: url("@/assets/images/service-web-bg.png") center center;
    background-size: auto 100%;
}

.flex-content {
    display: flex;
    flex-wrap: wrap;
}

.content {
    width: 1380px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.title-size {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
}
.desc-size {
    font-size: 18px;
    text-align: center;
}

/* 好网站 */
.hao-web {
    width: 100%;
    height: 612px;
}
.hao-web-top {
    display: flex;
    justify-content: space-between;
}
.web-top-left {
    width: 497px;
    margin-top: 96px;
}
.web-top-left-title {
    font-size: 30px;
    margin-bottom: 36px;
}
.web-bottom-total {
    display: flex;
    justify-content: space-between;
    margin-top: 44px;
}
.web-bottom-item {
    width: 311px;
    text-align: center;
}
.web-bottom-item-title {
    font-size: 24px;
    margin-bottom: 27px;
}
.web-bottom-item-desc {
    font-size: 14px;
}

/* web-seo */
.web-seo {
    width: 100%;
    height: 517px;
    background: #f6f6f6;
}
.web-seo-content {
    display: flex;
}
.seo-right-title {
    font-size: 30px;
}
.web-seo-right {
    margin-top: 204px;
}
.web-seo-top {
    width: 709px;
    text-align: center;
}
.seo-right-desc {
    color: #888888;
    font-size: 18px;
    margin-top: 13px;
    margin-bottom: 85px;
}
.web-seo-bottom {
    width: 670px;
    text-align: right;
    color: #333333;
}

.web-ysp {
    width: 100%;
    height: 504px;
    background: linear-gradient(91deg, #00abea 0%, #0075ea 100%);
}
.web-ysp-content-top {
    color: #ffffff;
    margin-top: 28px;
}
.web-ysp-content-desc {
    margin-top: 24px;
    margin-bottom: 113px;
    font-size: 16px;
}

.web-ysp-content-total {
    display: flex;
    justify-content: space-between;
}

.web-fwly {
    width: 100%;
    height: 827px;
}
.web-fwly-text {
    margin-top: 31px;
}
.web-fwly-text-desc {
    font-size: 16px;
    margin-top: 13px;
    margin-bottom: 79px;
    color: #888888;
}

.web-fwly-total {
    display: flex;
    justify-content: space-between;
}

.web-fwly-item1 {
    width: 512px;
    height: 582px;
    overflow: hidden;
    text-align: center;

    background: #f5f8fa;
}
.fwly-title {
    font-size: 24px;
    font-weight: 500;
    color: #555555;
    margin-bottom: 10px;
}
.fwly-desc {
    font-size: 16px;
    color: #555555;
}
.item2-top {
    width: 458px;
    height: 229px;
    background-color: #f5f8fa;
    padding-left: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}
.item2-top-text {
    width: 100%;
    text-align: center;
}
.item2-bottom {
    width: 480px;
    height: 340px;
    background-color: #f5f8fa;
    text-align: center;
}
.item2-bottom div:nth-child(1) {
    margin-bottom: 32px;
}

.web-fwly-item3 {
    width: 371px;
}
.item3-top {
    width: 371px;
    height: 316px;
    text-align: center;
    background-color: #f5f8fa;
    margin-bottom: 12px;
}
.item3-bottom {
    width: 371px;
    height: 233px;
    background-color: #f5f8fa;
    display: flex;
    padding-top: 21px;
    position: relative;
}
.item3-bottom-text {
    position: absolute;
    left: 158px;
}

.web-liyou {
    width: 100%;
    height: 1064px;
}
.liyou-title {
    margin-top: 53px;
    margin-bottom: 75px;
}

.hz-lc {
    width: 100%;
    background-color: #f5f8fa;
    height: 847px;
    overflow: hidden;
}
.hz-lc-row {
    width: 1380px;
    margin: 37px auto 23px auto;
}
.hz-title {
    color: #555555;
    font-size: 30px;
    text-align: center;
    margin-top: 37px;
}
.item-text-content {
    width: 289px;
    height: 202px;
}
.hz-title-desc {
    color: #888888;
    font-size: 18px;
    margin-top: 23px;
    text-align: center;
}
.hz-content {
    margin-top: 116px;
    display: flex;
    justify-content: space-between;
}
.container-liuc {
    width: 1380px;
    margin: 116px auto 0 auto;
}
.container-content {
    width: 100%;
    height: 495px;
}
.container-content img {
    width: 100%;
    height: 100%;
}
.weight {
    font-weight: 500;
}
</style>
